<template>
  <v-card
      class="elevation-1 rounded-0 mb-5"
  >
    <v-card-title>
      <!-- TODO: warum der by_user_id check? -->
      <v-icon
          v-if="notification.attributes.by_user_id === null"
          color="primary"
          class="mr-3"
          size="20"
      >
        fal fa-inbox-in
      </v-icon>
      <v-icon v-else color="primary" class="mr-3" size="20"
      >fal fa-inbox-out
      </v-icon
      >
      <h5 v-html="notification.attributes.data[$i18n.locale].subject"></h5>
    </v-card-title>

    <v-card-subtitle class="pt-4">
      <i class="fal fa-clock fa-fw"></i> {{ notification.attributes.created_at | dateTimeByLocale }} |
      {{ notification.attributes.data[$i18n.locale].from }}
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <p v-html="notification.attributes.data[$i18n.locale].body"></p>

      <div v-if="notification.attributes.type == 'user_company_invite'">
        <v-btn :disabled="notification.attributes.data.company_id != user.invitedCompanyId" color="secondary" @click="$refs.accept.show()">
          {{ $t('company.acceptInvite') }}
        </v-btn>
        <v-btn :disabled="notification.attributes.data.company_id != user.invitedCompanyId" color="secondary" class="ml-2" @click="$refs.decline.show()">
          {{ $t('company.declineInvite') }}
        </v-btn>
      </div>
    </v-card-text>

    <v-divider v-if="notification.attributes.read_at === null"/>

    <!-- TODO: warum der by_user_id check? -->
    <v-card-actions
        v-if="notification.attributes.read_at === null && notification.attributes.by_user_id === null"
    >
      <v-btn @click="$emit('read', notification)" icon>
        <v-icon size="20" color="primary">fal fa-check</v-icon>
      </v-btn>
    </v-card-actions>
    <company-accept-invite-modal ref="reload"></company-accept-invite-modal>
    <confirmation-modal ref="accept" @confirm="handleAccept">
      {{ $t('company.acceptInviteConfirmamtion') }}
    </confirmation-modal>
    <confirmation-modal ref="decline" @confirm="handleDecline">
      {{ $t('company.declineInviteConfirmamtion') }}
    </confirmation-modal>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import CompanyAcceptInviteModal from "@/components/exhibitor/Company/CompanyAcceptInviteModal.vue";
import ConfirmationModal from "@/components/base/ConfirmationModal.vue";

export default {
  name: 'Notification',

  components: {
    ConfirmationModal,
    CompanyAcceptInviteModal
  },

  data() {
    return {
      loading: false,
    }
  },

  props: {
    notification: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
    ]),
  },

  methods: {
    ...mapActions('auth', [
      'fetchUser',
    ]),

    async handleAccept() {
      this.loading = true

      await this.$axios.post(`/api/companies/${this.notification.attributes.data.company_id}/users/accept`, {})

      this.loading = false

      this.$refs.reload.show()
    },

    async handleDecline() {
      this.loading = true

      await this.$axios.post(`/api/companies/${this.notification.attributes.data.company_id}/users/decline`, {})

      await this.fetchUser()

      this.loading = false

      this.$router.go()
    },
  }
}
</script>
