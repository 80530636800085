<template>
  <v-dialog v-model="display" max-width="800px">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('onboarding.similarCompanyTitle') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <p class="mt-5" v-html="$t('onboarding.similarCompanyText')">
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn color="secondary" @click="hide" 
          :href="`mailto:support@german-pavilion.com?subject=`+encodeURIComponent($t('onboarding.similarCompanyContactSupportSubject'))">
          {{ $t('onboarding.similarCompanyContactSupport') }}
        </v-btn>
        <v-btn color="secondary" @click="ignore">
          {{ $t('onboarding.similarCompanyContinue') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AccreditationCollisionModal',

  data() {
    return {
      display: false,
    }
  },

  methods: {
    show() {
      this.display = true
    },

    hide() {
      this.display = false
    },

    ignore() {
      this.$emit('ignore')

      this.hide()
    }
  },
}
</script>
