<template>
  <div class="row mx-0">
    <div class="col-11">
      <date-input
          class="col-8"
          :label="$t('participation.editSteps.deadline.registrationDeadline')"
          :initial-value="form.deadline"
          :end-limit="true"
          :end-trade-fair-date="fair.attributes.end_date"
          @changed="updateDeadline"
      />
    </div>
    <div class="col-1">
      <v-btn
          class="elevation-0 secondary mt-5"
          :loading="form.loading"
          :disabled="form.loading"
          @click="handleSaveDeadline"
      >
        {{ $t('forms.buttons.save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/base/BaseDateInput'

export default {
  name: 'RegistrationDeadlineFields',

  components: {
    DateInput,
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      deadline: null,

      form: {
        loading: false,
      },
    }
  },

  created() {
    this.form.deadline = this.fair.attributes.registration_deadline
  },

  methods: {
    updateDeadline(deadline) {
      this.form.deadline = deadline
    },

    async handleSaveDeadline() {
      this.form.loading = true

      await this.$axios.patch(`/api/trade-fairs/${this.fair.id}`, {
        registration_deadline: this.$options.filters.dateTime(this.form.deadline),
      })

      this.$snackbar(this.$t('common.successApiMessage'))

      this.form.loading = false

      this.$emit('submit')
    },
  },
}
</script>
