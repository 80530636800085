export default {
  menu: {
    overview: 'Overview',
    myTradeFairParticipations: 'My trade fair participations',
    accreditation: 'Accreditation',
    findTradeFairs: 'Find trade fairs',
    helpAndAdvice: 'Help and advice',
    settings: 'Settings',
    changeProject: 'Change project',
    editRegistrationForm: 'Edit registration form',
    tradeFairSummary: 'Trade fair summary',
    participantRegistration: 'Participant registration',
    interestedPersons: 'Interested companies',
    sendNotification: 'Send notification',
    sendNotificationDescription: 'Send notification to system users',
    userManagement: 'User management',
    start: 'Start',
    fairs: 'Fairs',
  },
}
