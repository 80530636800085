<template>
  <v-dialog v-model="display" max-width="1250">
    <v-card>
      <v-card-title style="justify-content: space-between">
        <h4 class="primary--text">{{ $t('forms.buttons.preview') }}</h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <p><b>{{ $t('forms.fields.subject') }}:</b> {{ subjectEN }}</p>
            <p v-html="en"></p>
          </v-col>
          <v-col cols="6">
            <p><b>{{ $t('forms.fields.subject') }}:</b> {{ subjectDE }}</p>
            <p v-html="de"></p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" class="ml-auto" @click="hide">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="secondary" @click="send">
          {{ $t('notification.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'NotificationPreviewModal',

  props: {
    subjectDE: {
      type: String,
      required: true
    },
    subjectEN: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      display: false,

      de: null,
      en: null,
    }
  },

  methods: {
    show(en, de) {
      this.en = en
      this.de = de
      this.display = true
    },

    hide() {
      this.display = false
    },

    send() {
      this.$emit('send')

      this.hide()
    },
  },
}
</script>