<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">
          {{ $t('notification.sendNotification') }}
        </h4>
      </v-card-title>

      <v-card-text class="text--primary">
        <p>{{ $t('notification.description') }}</p>

        <v-select
            v-model="form.data.groups"
            :error-messages="errors.groups"
            :label="$t('forms.fields.groups')"
            :items="recipients"
            item-value="id"
            item-text="title"
            multiple
            chips
        />

        <v-row>
          <v-col cols="6">
            <v-text-field
                v-model="form.data.subject.en"
                :error-messages="errors.subject"
                :label="$t('forms.fields.subject') + ` (${$t('forms.fields.english')})`"
            />

            <vue-editor
                :placeholder="$t('forms.fields.body') + ` (${$t('forms.fields.english')})`"
                rows="6"
                class="mb-2"
                v-model="form.data.body.en"
            ></vue-editor>
            <validation-general-error class="mt-2" :errors="errors.body"/>

            <vue-editor
                :placeholder="$t('forms.fields.signature') + ` (${$t('forms.fields.english')})`"
                rows="6"
                class="mb-2"
                v-model="form.data.signature.en"
            ></vue-editor>
            <validation-general-error class="mt-2" :errors="errors.signature"/>
          </v-col>

          <v-col cols="6">
            <v-text-field
                v-model="form.data.subject.de"
                :error-messages="errors.subject"
                :label="$t('forms.fields.subject') + ` (${$t('forms.fields.german')})`"
            />

            <vue-editor
                :placeholder="$t('forms.fields.body') + ` (${$t('forms.fields.german')})`"
                rows="6"
                class="mb-2"
                v-model="form.data.body.de"
            ></vue-editor>
            <validation-general-error class="mt-2" :errors="errors.body"/>

            <vue-editor
                :placeholder="$t('forms.fields.signature') + ` (${$t('forms.fields.german')})`"
                rows="6"
                class="mb-2"
                v-model="form.data.signature.de"
            ></vue-editor>
            <validation-general-error class="mt-2" :errors="errors.signature"/>
          </v-col>

        </v-row>
      </v-card-text>


      <v-divider/>

      <v-card-actions class="py-3 px-4">
        <v-btn
            color="secondary"
            class="elevation-0 ml-auto"
            @click="handleNotificationPreview"
            :loading="form.loading"
        >
          {{ $t('forms.buttons.preview') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <notification-preview-modal :subjectDE="form.data.subject.de" :subjectEN="form.data.subject.en" ref="preview" @send="handleNotificationSending"></notification-preview-modal>
  </v-container>
</template>

<script>
import validation from '@/mixins/validate'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import ValidationGeneralError from '../../components/base/ValidationGeneralError'
import {translation} from '@/utilities/helpers/validation'
import NotificationPreviewModal from "@/components/admin/Notification/Modals/NotificationPreviewModal.vue";

export default {
  name: 'AdminSendNotification',

  components: {
    NotificationPreviewModal,
    ValidationGeneralError
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        loading: false,

        data: {
          groups: [],
          subject: { en:'', de:''},
          body: {},
          signature: {},
        },

        validationRules: {
          groups: {
            required,
          },
          subject: {
            translation: translation(),
          },
          body: {
            translation: translation(),
          },
          signature: {
            translation: translation(),
          },
        },
      },

      count: {
        exhibitors: 0,
        organizers: 0,
        associations: 0,
        supervisors: 0,
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    recipients() {
      return [
        {
          id: 1,
          title: this.$t('notification.exhibitors') + ` [${this.count.exhibitors}]`,
        },
        {
          id: 2,
          title: this.$t('notification.organizers') + ` [${this.count.organizers}]`,
        },
        {
          id: 3,
          title: this.$t('notification.associations') + ` [${this.count.associations}]`,
        },
        {
          id: 4,
          title: this.$t('notification.supervisors') + ` [${this.count.supervisors}]`,
        },
      ]
    }
  },

  async created() {
    await this.load()
  },

  methods: {
    async load() {
      this.form.data.signature.en = this.$t('notification.adminSignature', 'en')
      this.form.data.signature.de = this.$t('notification.adminSignature', 'de')

      let response = await this.$axios.post(`/api/notifications/info-admin`)
      this.count.exhibitors = response.data.data[1]
      this.count.organizers = response.data.data[2]
      this.count.associations = response.data.data[3]
      this.count.supervisors = response.data.data[4]
    },

    async handleNotificationPreview() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        let response = await this.$axios.post(`/api/notifications/preview-admin`, { ...this.form.data })

        this.$refs.preview.show(response.data.data.en, response.data.data.de)
        this.$v.$reset()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },

    async handleNotificationSending() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        let response = await this.$axios.post(`/api/notifications/send-admin`, { ...this.form.data })

        this.$snackbar(this.$t('notification.sendNotificationCount', { count: response.data.data.count }))
        this.$v.$reset()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
