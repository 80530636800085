<template>
  <v-dialog v-model="display" max-width="800px" persistent>
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('company.acceptInviteTitle') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-4">
        {{ $t('company.acceptInviteText') }}
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="secondary" @click="hide">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CompanyAcceptInviteModal',

  data() {
    return {
      display: false,
    }
  },

  methods: {
    ...mapActions('auth', [
      'logOut',
    ]),

    show() {
      this.display = true
    },

    hide() {
      this.display = false

      this.handleLogout()
    },

    async handleLogout() {
      await this.logOut()

      window.location.href = await this.$router.resolve({ name: 'login' }).href
    },
  },
}
</script>
