<template>
  <v-card
    class="elevation-1 mb-5 rounded-0"
    :loading="isLoading"
  >
    <v-card-title class="px-5">
      <h3 class="primary--text">{{ fair.attributes.name_display }}</h3>
      <v-chip
        class="green white--text ml-auto"
        small
      >
        {{ registrationStatus }}
      </v-chip>
    </v-card-title>
    <v-card-text
      class="text--primary px-5"
      v-if="!isLoading"
    >
      <v-row justify="space-around">

        <v-col>
          <small>{{ $t('tradeFair.date') }}</small>
          <p>{{ fair.attributes.start_date_display ? fair.attributes.start_date_display[$i18n.locale] : '' }} - {{ fair.attributes.end_date | dateByLocale }}</p>
        </v-col>

        <v-col v-if="fair.relationships.country">
          <small>{{ $t('tables.tradeFair.city') }}</small>
          <p>{{ fair.attributes.city }}, {{ fair.relationships.country.translations[$i18n.locale].country }}</p>
        </v-col>

        <v-col>
          <small>{{ $t('tables.tradeFair.registrationDeadline') }}</small>
          <p v-if="fair.attributes.registration_deadline">
            {{ fair.attributes.registration_deadline | dateByLocale }}
          </p>
          <p v-else>
            {{ $t('tables.tradeFair.notYetKnown') }}
          </p>
        </v-col>

        <v-col v-if="fair.attributes.type">
          <small>{{ $t('tables.tradeFair.participationForm') }}</small>
          <p>{{ fair.attributes.type.type[$i18n.locale] }}</p>
        </v-col>

        <v-col v-if="fair.relationships.participations">
          <small>{{ $t('tradeFair.confirmedRegistrations') }}</small>
          <h1 class="primary--text">{{ fair.relationships.participations.length }}</h1>
        </v-col>

        <v-col v-if="fair.relationships.interested_companies">
          <small>{{ $t('interest.interestedPersons') }}</small>
          <h1 class="primary--text">{{ fair.relationships.interested_companies.length }}</h1>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TradeFairCard',

  props: {
    fair: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    registrationStatus() {
      return this.$moment(this.fair.attributes.registration_deadline).isAfter()
        ? this.$t('tables.tradeFair.statuses.registrationDeadlineRuns')
        : this.$t('tables.tradeFair.statuses.registrationDeadlineExpired')
    }
  },
}
</script>
