<template>
  <fragment>
    <v-col cols="12">
      <h3 class="mb-5">
        {{ $t('accreditation.steps.publicParticipation.subTitle') }}
      </h3>
      <p class="mb-0" v-html="$t('accreditation.steps.publicParticipation.hintTitle')"></p>
      <p class="red--text mb-5 pb-5" v-html="$t('accreditation.steps.publicParticipation.hintTitleRed')"></p>

      <div>
        <p class="font-weight-bold">
          1. {{ $t('accreditation.steps.publicParticipation.declarations.publicSectorInvolvement') }}
          <v-icon
              size="20"
              style="cursor: pointer; width: 20px; height: 20px"
              class="align-start primary--text mt-1 ml-1"
              @click="openModalSectorInvolvement"
          >
            fal fa-question-circle
          </v-icon>
        </p>
        <v-col cols="4" class="px-0 py-0">
          <v-slider
              v-model="form.data.publicSectorInvolvement"
              :tick-labels="ranges"
              min="-1"
              max="1"
              ticks="always"
              tick-size="3"
              :error-messages="errors.publicSectorInvolvement"
          >
          </v-slider>
        </v-col>
      </div>

      <v-divider class="my-4" />

      <div>
        <p class="font-weight-bold">
          2. {{ $t('accreditation.steps.publicParticipation.declarations.publicSectorInstitution') }}
        </p>
        <v-col cols="4" class="px-0 py-0">
          <v-slider
              v-model="form.data.publicSectorInstitution"
              :tick-labels="ranges"
              min="-1"
              max="1"
              ticks="always"
              tick-size="3"
              :error-messages="errors.publicSectorInstitution"
          >
          </v-slider>
        </v-col>
      </div>

      <v-divider class="my-4" />

      <div>
        <p class="font-weight-bold">
          3. {{ $t('accreditation.steps.publicParticipation.declarations.publicSectorMajority') }}
          <v-icon
              size="20"
              style="cursor: pointer; width: 20px; height: 20px"
              class="align-start primary--text mt-1 ml-1"
              @click="openModalSectorMajority"
          >
            fal fa-question-circle
          </v-icon>
        </p>
        <v-col cols="4" class="px-0 py-0">
          <v-slider
              v-model="form.data.publicSectorMajority"
              :tick-labels="ranges"
              min="-1"
              max="1"
              ticks="always"
              tick-size="3"
              :error-messages="errors.publicSectorMajority"
          >
          </v-slider>
        </v-col>
      </div>

      <error-message :messages="form.errorMessages"/>
    </v-col>


    <v-dialog v-model="modalOpenSectorInvolvement" max-width="800px">
      <v-card>
        <v-card-title class="justify-space-between">
          <h4 class="primary--text">
            {{ $t('accreditation.steps.publicParticipation.subTitle') }}
          </h4>
          <v-icon size="17" @click="closeModalSectorInvolvement">fal fa-times</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <p class="mt-5" v-html="$t('accreditation.infoSponsoredInstitutionalOrPublic')">
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" @click="closeModalSectorInvolvement">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalOpenSectorMajority" max-width="800px">
      <v-card>
        <v-card-title class="justify-space-between">
          <h4 class="primary--text">
            {{ $t('accreditation.steps.publicParticipation.subTitle') }}
          </h4>
          <v-icon size="17" @click="closeModalSectorMajority">fal fa-times</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <p class="mt-5" v-html="$t('accreditation.infoSectorMajority')">
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" @click="closeModalSectorMajority">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-row
        class="mx-0 mb-2 mt-5 px-3"
    >
      <v-btn
          v-if="!accreditation.attributes.accreditation_approved_at && accreditation_old.attributes.accreditation_approved_at"
          @click="$emit('cancel')"
          color="error"
          class="elevation-0"
      >
        {{ $t('common.cancel') }}
      </v-btn>

      <v-btn
          @click="handleSubmit"
          color="primary"
          class="elevation-0 ml-auto"
      >
        {{ $t('forms.buttons.continue') }}
      </v-btn>
    </v-row>
  </fragment>
</template>

<script>
import {SAVE_PUBLIC_PARTICIPATION} from '@/store/loading-types'
import validation from '@/mixins/validate'
import ErrorMessage from '@/components/base/Messages/ErrorMessage'

export default {
  name: 'PublicParticipation',

  components: {
    ErrorMessage,
  },

  mixins: [validation],

  props: {
    accreditation: Object,
    accreditation_old: Object,
  },

  data() {
    return {
      modalOpenSectorInvolvement: false,
      modalOpenSectorMajority: false,

      form: {
        customFieldsMap: {
          publicSectorInvolvement: 'declared_public_sector_involvement',
          publicSectorInstitution: 'declared_public_sector_institution',
          publicSectorMajority: 'declared_public_sector_majority',
        },

        data: {
          publicSectorInvolvement: false,
          publicSectorInstitution: false,
          publicSectorMajority: false,
        },

        validationRules: {
          publicSectorInvolvement: {},
          publicSectorInstitution: {},
          publicSectorMajority: {},
        },

        custom: {
        },
        errorMessages: [],
      },

      loaders: {
        savePublicParticipation: SAVE_PUBLIC_PARTICIPATION,
      },
    }
  },

  computed: {
    ranges() {
      return [
          this.$t('common.range.false'),
          this.$t('common.range.select'),
          this.$t('common.range.true'),
      ]
    },
  },

  async created() {
    let maps = {null: 0, false: -1, true: 1, 0: -1, 1: 1}

    this.form.data.publicSectorInvolvement = maps[this.accreditation.attributes.declared_public_sector_involvement]
    this.form.data.publicSectorInstitution = maps[this.accreditation.attributes.declared_public_sector_institution]
    this.form.data.publicSectorMajority = maps[this.accreditation.attributes.declared_public_sector_majority]
  },

  methods: {
    openModalSectorInvolvement() {
      this.modalOpenSectorInvolvement = true
    },
    closeModalSectorInvolvement() {
      this.modalOpenSectorInvolvement = false
    },

    openModalSectorMajority() {
      this.modalOpenSectorMajority = true
    },
    closeModalSectorMajority() {
      this.modalOpenSectorMajority = false
    },

    async handleSubmit() {
      this.form.errorMessages = []

      this.resetServerErrors()

      if (!this.form.data.publicSectorInvolvement || !this.form.data.publicSectorInstitution || !this.form.data.publicSectorMajority) {
        let errors = {}

        if (!this.form.data.publicSectorInvolvement) errors.declared_public_sector_involvement = [this.$t('validation.mandatory')]
        if (!this.form.data.publicSectorInstitution) errors.declared_public_sector_institution = [this.$t('validation.mandatory')]
        if (!this.form.data.publicSectorMajority) errors.declared_public_sector_majority = [this.$t('validation.mandatory')]

        this.setServerErrors(errors)
        return
      }

      let maps = {'0': null, '-1': false, '1': true}
      this.form.loading = true
      try {
        await this.$axios.patch(`/api/accreditations/${this.accreditation.id}`, {
          declared_public_sector_involvement: maps[this.form.data.publicSectorInvolvement],
          declared_public_sector_institution: maps[this.form.data.publicSectorInstitution],
          declared_public_sector_majority: maps[this.form.data.publicSectorMajority],
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
