<template>
  <v-container
    class="pa-8"
    fluid
  >
    <v-row class="align-content-stretch">

      <v-col
        v-for="feature in features"
        :key="feature.name"
        cols="3"
        class="d-flex align-items-center"
      >
        <v-card
          :to="{ name: feature.route }"
          class="col white text-center pa-5 elevation-1"
        >
          <v-icon
            size="40"
            class="primary--text mb-5"
          >
            fal {{ feature.icon }} fa-fw
          </v-icon>
          <h3 class="mb-3">{{ feature.title }}</h3>
          <p>{{ feature.description }}</p>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AdminDashboard',

  computed: {
    features() {
      return [
        {
          title: this.$t('accreditation.accreditations'),
          description: this.$t('accreditation.checkAndApproveNew'),
          icon: 'fa-id-card',
          route: 'admin.accreditations',
        },
        {
          title: this.$t('company.companies'),
          description: this.$t('company.overviewAndAdministrate'),
          icon: 'fa-industry-alt',
          route: 'admin.companies',
        },
        {
          title: this.$t('users.users'),
          description: this.$t('users.manageUserTypesAndAccess'),
          icon: 'fa-users',
          route: 'admin.users',
        },
        {
          title: this.$t('tradeFair.tradeFairs'),
          description: 'Veranstaltungsübersicht und Anmeldestand der Auslandsmesseprogramme.',
          icon: 'fa-calendar-alt',
          route: 'admin.fairs',
        },
        {
          title: this.$t('help.helpEntries'),
          description: this.$t('help.manageAndEditArticles'),
          icon: 'fa-question-circle',
          route: 'admin.helps',
        },
        {
          title: this.$t('help.answerQuestions'),
          description: this.$t('help.manageAndEditTopicSections'),
          icon: 'fa-comments-alt',
          route: 'admin.faqs',
        },
        {
          title: this.$t('menu.sendNotification'),
          description: this.$t('menu.sendNotificationDescription'),
          icon: 'fa-comment-lines',
          route: 'admin.notifications.send',
        },
      ]
    },
  },
}
</script>
