<template>
  <v-container v-if="participation.id && fair.id" fluid>
    <v-card class="elevation-1 mb-5 rounded-0">
      <v-card-title class="px-5">
        <h3 class="primary--text">{{ fair.attributes.name_display }}</h3>
        <v-col class="text-right">
          <v-chip class="secondary white--text ml-2" small>
            {{ $t('tables.participation.pageStatus.' + participation.attributes.status) }}
          </v-chip>
        </v-col>
      </v-card-title>
      <v-card-text class="text--primary px-5">
        <v-row justify="space-around">
          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.date') }}</small>
            <p>
              {{ fair.attributes.start_date | dateByLocale }} -
              {{ fair.attributes.end_date | dateByLocale }}
            </p>
          </v-col>

          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.city') }}</small>
            <p>{{ fair.attributes.city }}, {{ fair.relationships.country.translations[$i18n.locale].country }}</p>
          </v-col>

          <v-col v-if="fair.attributes.registration_deadline" cols="3">
            <small>{{ $t('tables.tradeFair.registrationDeadline') }}</small>
            <p>
              {{ fair.attributes.registration_deadline | dateByLocale }}
            </p>
          </v-col>

          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.participationForm') }}</small>
            <p>{{ fair.attributes.type.type[$i18n.locale] }}</p>
          </v-col>

          <v-col
              cols="12"
              class="pt-0 mt-0"
          >
            <v-btn :to="{ name: 'exhibitor.participations.application', params: { id: fair?.id } }" color="secondary" class="elevation-0 mr-3"
                   v-if="fair.attributes.flag_registration_is_open && !participation.attributes.submitted_at" outlined>
              {{ $t('tables.participation.form.viewAndEditRegistration') }}
            </v-btn>
            <v-btn v-else :to="{ name: 'exhibitor.participations.application', params: { id: fair?.id } }" color="secondary" class="elevation-0 mr-3" outlined>
              {{ $t('tables.participation.form.viewRegistration') }}
            </v-btn>

            <v-btn color="secondary" class="elevation-0" v-if="participation.attributes.approved_at"
                   @click="downloadRegistrationConfirmation({ participation: participation, tradeFair: fair })"
                   outlined>
              <v-icon class="mr-2" size="20">fal fa-file-pdf</v-icon>
              {{ $t('tables.participation.form.downloadRegistrationConfirmation') }}
            </v-btn>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-expansion-panels
        v-if="fair.id"
        class="mb-10 elevation-0 rounded-0"
        v-model="panels"
        multiple
        flat
    >

      <v-expansion-panel
          v-if="fair.attributes.registration_type === 'print' && fair.attributes.flag_registration_is_open"
          class="elevation-1 rounded-0"
      >
        <v-expansion-panel-header>
          <h3>
            {{ $t('tables.participation.form.registrationAndParticipation') }}
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            {{ $t('tables.participation.form.atPresentOnlineRegistrationVia') }}
          </p>
          <p>
            {{ $t('tables.participation.form.registrationDocumentsOnTheAUMA') }}
            <a :href="fair.attributes.link_auma_database">{{ fair.attributes.link_auma_database }}</a>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <participation-and-conditions
          v-if="!(fair.attributes.registration_type === 'print' && !fair.attributes.flag_registration_is_open )"
          :company="company"
          :fair="fair"
      />

      <execution-company
          :trade-fair="fair"
          :organizer="fair.relationships.organizer"
      />

      <fair-information :fair="fair"/>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ParticipationAndConditions from '@/components/common/TradeFair/ParticipationAndConditions'
import ExecutionCompany from '@/components/common/TradeFair/ExecutionCompany'
import FairInformation from '@/components/common/TradeFair/FairInformation'
import date from '@/mixins/date'
import {PARTICIPATION_STATUS} from '@/enums/participationStatus'

export default {
  name: 'ExhibitorParticipationDetail',

  mixins: [date],

  components: {
    ParticipationAndConditions,
    ExecutionCompany,
    FairInformation,
  },

  data() {
    return {
      panels: [],

      fair: {},
      participation: {},

      form: {
        loading: false,
      },

      PARTICIPATION_STATUS
    }
  },

  computed: {
    ...mapGetters('company', ['company']),
  },

  async created() {
    this.load()
  },

  methods: {
    ...mapActions('participation', [
      'setNotedTradeFair',
      'unsetNotedTradeFair',
      'downloadRegistrationConfirmation',
    ]),

    ...mapActions('auth', ['fetchCompany']),

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/participations/${this.$route.params.id}`, {
        params: {
          'languages': '*',
          'include': [
            'tradeFair',
            'tradeFair.country',
            'tradeFair.industrySectors',
            'tradeFair.exhibitionAreas',
            'tradeFair.spaces',
            'tradeFair.packages',
            'tradeFair.services',
            'tradeFair.organizer',
            'coexhibitors',
          ],
          'sort': '',
        },
      })).data

      this.fair = response.data.relationships.trade_fair
      this.participation = response.data

      this.form.loading = false
    },
  },

  watch: {
    async '$route.params.id'() {
      this.load()
    }
  },
}
</script>
