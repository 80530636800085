export default {
  onboarding: {
    header: {
      thanksForRegistration: 'Thank you for your registration',
      completeProfile: 'Please take some time to complete your profile so that we can show you the most relevant information possible.',
    },
    steps: {
      companyData: {
        title: 'Company data',
      },
      countriesAndIndustries: {
        title: 'Countries & Industries',
        description: 'Which countries and which industries are interesting for your company to participate in a trade fair?',
        fields: {
          countries: 'Countries',
          industrySectors: 'Industry sectors',
        },
      },
      tradeFairs: {
        title: 'Trade fairs',
        description: 'Which trade fairs are you particularly interested in? (optional)',
        fields: {
          tradeFairs: 'Trade fairs',
        }
      },
      nextSteps: {
        title: 'Your next steps',
        description: 'Thanks for this helpful information. These are your possible next steps:',
        actions: {
          findTradeFairs: 'Find trade fair participations that are of interest to your company.',
          carryOutAccreditation: 'Carry out the accreditation for your company so that you can book your trade fair participation via this platform.',
          useHelpAndAdvice: 'Use the "Help & Advice" functions to familiarize yourself with the selection and process of participating in a foreign trade fair.',
        },
      },
        toAccreditation: 'On to the accreditation',
        nextStep: {
          title: 'Next Step',
          description: 'Thanks for this helpful information.',
        }
      },
    similarCompanyTitle: 'Your company may already have an account',
    similarCompanyText: 'We have recognized that your company is most likely already accredited. If you rule this out, simply continue with the accreditation. Otherwise, you may be able to save yourself the effort of accreditation.<br /><br /> Ask in your company or get in touch with our support team so that you can also use the existing account.',
    similarCompanyContinue: 'Continue process',
    similarCompanyContactSupport: 'Contact support',
    similarCompanyContactSupportSubject: 'German Pavilion Online Registration: Request for assignment to another company',
  },
}
