export default {
  validation: {
    required: '{field} erforderlich',
    mandatory: 'Diese Angabe ist erforderlich',
    translation: '{field} erforderlich',
    requiredIf: '{field} erforderlich',
    email: '{field} muss eine gültige E-Mail sein',
    minLength: '{field} muss aus mindestens {length} Zeichen bestehen',
    maxLength: '{field} darf höchstens {length} Zeichen enthalten',
    sameAs: '{field} muss mit dem {sameAsField} Feld identisch sein',
    sameAsPassword: '{field} muss mit dem {sameAsField} Feld identisch sein',
    checkboxRequired: '{value} muss akzeptiert werden',
    url: '{field} muss eine gültige URL sein',
    minValue: '{field} darf nicht kleiner sein als {limit}',
    maxValue: '{field} darf nicht größer sein als {limit}',
    participationIsNotCreated: 'Die Teilnahme ist noch nicht angelegt. Bitte füllen Sie zuerst das Ausstellerformular aus',
    activateCheckboxesToProceed: 'Bitte bestätigen Sie die Aussagen',
    integer: '{field} muss eine positive ganze Zahl sein',
    minFileSize: ' Die Datei darf höchstens {limit} klein sein',
    maxFileSize: ' Die Datei darf höchstens {limit} groß sein',
  },
}
