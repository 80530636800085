<template>
  <v-expansion-panel v-if="participations.length" class="elevation-1 rounded-0">
    <v-expansion-panel-header>
      <div>
        <h3>{{ $t('tradeFair.confirmedExhibitorApplications') }}</h3>
        <h6 v-if="fair.attributes.registration_type === REGISTRATION_TYPES.PRINT">
          {{ $t('participation.supervisorPrintHint') }}</h6>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>

      <p v-if="fair.attributes.registration_type === REGISTRATION_TYPES.PRINT">
        {{ $t('participation.supervisorPrintHint') }}
      </p>

      <template v-else>

        <v-card-text class=" py-0 px-2">
          <p v-if="fair.id" class="row ma-0 py-2">
            <v-chip
                v-if="fair.included.participations_count"
                class="mr-2 mt-2"
                color="warning"
                pill
                small
            >
              {{ fair.included.participations_count }}
              {{ $t(`participation.confirmed`) }}
            </v-chip>
            <v-chip
                v-if="fair.included.space_total"
                class="mr-2 mt-2"
                color="primary"
                pill
                small
            >
              {{ $t('participation.totalSpace') }}: {{ fair.included.space_total }} m<sup>2</sup>
            </v-chip>
            <v-btn class="ml-auto" color="secondary" @click="downloadXlsx"
                   style="text-transform: none;" text>
              <v-icon size="16" class="mr-2">
                fal fa-file-pdf
              </v-icon>
              {{ $t('interest.exportAsExcelSheet') }}
            </v-btn>
            <v-btn class="ml-2"  color="secondary" @click="downloadPdf"
                   style="text-transform: none;" text>
              <v-icon size="16" class="mr-2">
                fal fa-file-pdf
              </v-icon>
              {{ $t('interest.exportAsPDFFile') }}
            </v-btn>
          </p>
          <small
              v-if="fair.included?.coexhibitors_count"
              class="pt-2"
          >
            {{ $t('participation.coExhibitors') }}: {{ fair.included?.coexhibitors_count }}
          </small>
        </v-card-text>

        <v-data-table
            :headers="headers"
            :loading="form.loading"
            :items="rows"
            :page.sync="form.page"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
            :server-items-length="form.total"
        >

          <template v-slot:item.company="{ item }">
            <span v-if="item.is_subexhibitor">{{ item.sub_exhibitor.attributes.company }}</span>
            <div v-else>
              <router-link v-if="isAdmin" class="text-center"
                            :to="{ name: 'admin.company', params: { id: item.relationships.company?.attributes.company_id } }">
                {{ item.relationships.company ? item.relationships.company?.attributes.company : '' }}
              </router-link>
              <router-link v-else class="text-center"
                            :to="{ name: 'supervisor.company', params: { id: item.relationships.company?.attributes.company_id } }">
                {{ item.relationships.company ? item.relationships.company?.attributes.company : '' }}
              </router-link>
            </div>
          </template>

          <template v-slot:item.city="{ item }">
            <span v-if="!item.is_subexhibitor">{{ item.relationships.company?.attributes.city }}</span>
            <span v-else>{{ item.sub_exhibitor.attributes.city }}</span>
          </template>

          <template v-slot:item.country="{ item }">
            <span v-if="!item.is_subexhibitor">{{ item.relationships.company?.relationships.country.translations[$i18n.locale].country }}</span>
            <span v-else>{{ item.sub_exhibitor?.relationships.country?.translations[$i18n.locale].country }}</span>
          </template>

          <template v-slot:item.isSubexhibitor="{ item }">
            <span v-if="item.is_subexhibitor">UA</span>
            <span v-else>HA</span>
          </template>

          <template v-slot:item.booked="{ item }">
            <span v-if="!item.is_subexhibitor && item.attributes.booking_type === BOOKING_TYPES.SPACE && item.relationships.spaces.length"
                  v-html="item.relationships.spaces.reduce((val, el) => el.attributes.size + val, 0) + ' m<sup>2</sup> ' + getConstructionText(item)">
            </span>
              <span v-if="!item.is_subexhibitor && item.attributes.booking_type === BOOKING_TYPES.PACKAGE && item.relationships.package"
                    v-html="item.relationships.package.attributes.title[$i18n.locale]">
            </span>
          </template>

          <template v-slot:item.submitted_at="{ item }">
            {{ item.attributes.submitted_at | dateTimeByLocale }}
          </template>

          <template v-slot:item.coexhibitors="{ item }">
            {{ !item.is_subexhibitor ? item.relationships.coexhibitors?.length : '' }}
          </template>

          <template v-slot:item.fullPayment="{ item }">
            <v-icon size="16">
              fal fa-fw {{ item.included.is_approved ? 'fa-check' : 'fa-times' }}
            </v-icon>
          </template>

          <template v-slot:item.download="{ item }">
            <v-btn v-if="!item.is_subexhibitor" color="secondary" @click="downloadRegistrationConfirmation({participation: item, tradeFair: fair})"
                   style="text-transform: none;" text>
              <v-icon size="16" class="mr-2">
                fal fa-file-pdf
              </v-icon>
              {{ $t('tables.participation.form.registrationConfirmation') }}
            </v-btn>

          </template>

          <template v-slot:item.notes="{ item }">
            <div v-if="!item.is_subexhibitor">
              <router-link
                  v-if="isAdmin"
                  class="text-center"
                  :to="{ name: 'admin.notes', params: { id: item.relationships.company?.attributes.company_id } }">
                {{ item.included.notes_count ?? '-' }}
              </router-link>
              <router-link
                  v-else
                  class="text-center"
                  :to="{ name: 'supervisor.notes', params: { id: item.relationships.company?.attributes.company_id } }">
                {{ item.included.notes_count ?? '-' }}
              </router-link>
            </div>
          </template>
        </v-data-table>
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {BOOKING_TYPES} from "@/enums/bookingType";
import {REGISTRATION_TYPES} from "@/enums/registrationTypes";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ApplicationsList',

  data() {
    return {
      participations: [],

      form: {
        loading: false,
      },

      options: {},

      BOOKING_TYPES,
      REGISTRATION_TYPES
    }
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  computed: {
    ...mapGetters('countries', ['countries']),

    ...mapGetters('auth', [
      'isAdmin',
      'isAssociation',
      'isSupervisor'
    ]),

    headers() {
      return [
        {
          text: this.$t('forms.fields.company'),
          sortable: true,
          value: 'company',
        },
        {
          text: this.$t('forms.fields.city'),
          sortable: true,
          value: 'city',
        },
        {
          text: this.$t('forms.fields.country'),
          sortable: true,
          value: 'country',
        },
        {
          text: this.$t('participation.haUa'),
          sortable: false,
          value: 'isSubexhibitor',
        },
        {
          text: this.$t('participation.registeredOn'),
          value: 'submitted_at',
          sortable: true,
          dataType: 'Date',
        },
        {
          text: this.$t('participation.coExhibitor'),
          value: 'coexhibitors',
          sortable: true,
        },
        {
          text: this.$t('participation.booking'),
          value: 'booked',
          sortable: true,
        },
        {
          text: '',
          value: 'download',
          sortable: false,
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
        {
          text: this.$t('help.notes.notes'),
          value: 'notes',
          sortable: false,
          align: 'right',
        },
      ];
    },

    rows() {
      var arr = []

      if (!this.participations) {
        return arr
      }

      var participations = JSON.parse(JSON.stringify(this.participations))

      for (var i in participations) {
        participations[i].is_subexhibitor = false
        participations[i].sub_exhibitor = participations[i].relationships.coexhibitors
            .map((el) => el.attributes.company)
            .join('\r\n')

        arr.push(participations[i])

        if (!participations[i].relationships.coexhibitors.length) {
          continue
        }

        for (var key in participations[i].relationships.coexhibitors) {
          arr.push({
            ...participations[i],
            id: participations[i].relationships.coexhibitors[key].id,
            is_subexhibitor: true,
            sub_exhibitor: participations[i].relationships.coexhibitors[key],
            parent_exhibitor: participations[i].relationships.company?.attributes.company,
          })
        }
      }

      return arr
    },
  },

  async created() {
    this.fetchCountries()

    this.load()
  },

  methods: {
    ...mapActions('countries', [
      'fetchCountries',
    ]),

    ...mapActions('participation', [
      'downloadRegistrationConfirmation',
    ]),

    ...mapActions('tradefair', [
      'downloadTradeFairParticipants',
      'downloadParticipantsSupervisorXlsx',
      'downloadParticipantsSupervisorPdf',
    ]),

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get(`/api/trade-fairs/${this.fair.id}/participants`, {
        params: {
          'languages': '*',
          'include': [
            'tradeFair',
            'coexhibitors',
            'company',
            'tradeFairSpaces',
            'tradeFairPackages',
            'tradeFairServices',
            'areas',
            'goods',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.participations = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    getConstructionText(fair) {
      return fair.relationships.spaces[0].attributes.construction_included
          ? this.$t('participation.exhibitionSpace.withStandConstruction')
          : this.$t('participation.exhibitionSpace.withoutStandConstruction')
    },

    async downloadXlsx() {
      if (this.isAdmin) {
        await this.downloadTradeFairParticipants({
          id: this.fair.id,
          name: this.fair.attributes.name_display + ' participants'
        })
      } else {
        await this.downloadParticipantsSupervisorXlsx({
          id: this.fair.id,
          name: this.fair.attributes.name_display + ' participants'
        })
      }
    },

    async downloadPdf() {
      await this.downloadParticipantsSupervisorPdf({
        id: this.fair.id,
        name: this.fair.attributes.name_display + ' participants'
      })
    },
  },

  watch: {
    'options'() {
      this.load()
    },
  }
}
</script>
