<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      no-title
      scrollable
      :allowed-dates="allowedDates"
      @change="handleChange"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="handleDateChange"
      >
        {{ $t('common.apply') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DateInput',

  props: {
    label: {
      type: String,
      required: false,
    },

    initialValue: {
      type: String,
      required: false,
    },

    endLimit: {
      type: Boolean,
      required: false,
      default: true,
    },

    startTradeFairDate: {
      type: String,
      required: false,
    },

    endTradeFairDate: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      menu: false,
      date: null,
    }
  },

  computed: {
    currentDate() {
      return this.$moment()
    },

    startDate() {
      return this.currentDate.toISOString()
    },

    endDate() {
      return this.endLimit ? this.$moment(this.endTradeFairDate).toISOString() : null
    },

    formattedDate() {
      return this.$root.$options.filters.dateByLocale(this.date)
    },
  },

  created() {
    if (this.initialValue) {
      this.date = this.initialValue
    }
  },

  methods: {
    handleChange(value) {
      this.date = value
    },

    handleDateChange() {
      this.$refs.menu.save(this.date)
      this.$emit('changed', this.date)
    },

    allowedDates(val) {
      return val >= this.startDate.slice(0, 10) && val <= this.endDate;
    }
  },
}
</script>
