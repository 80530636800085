<template>
  <v-form>
    <v-row>
      <v-col cols="12">
        <h3>{{ $t('accreditation.steps.companyData.subTitle') }}</h3>
      </v-col>

      <v-col cols="6" class="pr-10">
        <v-text-field
          v-model="form.data.company"
          :error-messages="errors.company"
          :label="$t('forms.fields.company') | required"
          :loading="form.loading"
          required
        />

        <v-text-field
          v-model="form.data.street"
          :error-messages="errors.street"
          :label="$t('forms.fields.street') | required"
          :loading="form.loading"
        />

        <v-text-field
          v-model="form.data.postalcode"
          :error-messages="errors.postalcode"
          :label="$t('forms.fields.postalcode') +
            (isRequiredPostalCode ? '*' : '')"
          :loading="form.loading"
        />

        <v-text-field
          v-model="form.data.city"
          :error-messages="errors.city"
          :label="$t('forms.fields.city') | required"
          :loading="form.loading"
        />

        <v-text-field
          v-model="form.data.address_supplement"
          :error-messages="errors.address_supplement"
          :label="$t('forms.fields.addresssupplement')"
          :loading="form.loading"
        />
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="form.data.country"
          :error-messages="errors.country"
          :label="$t('forms.fields.country') | required"
          :items="prioritizedCountries"
          :item-text="`translations.${$i18n.locale}.country`"
          item-value="id"
          :loading="form.loading"
          :disabled="accreditation_old && accreditation_old.attributes.country_id && !!accreditation_old.attributes.accreditation_approved_at"
        />

        <v-select
          v-model="form.data.federal_state"
          :error-messages="errors.federal_state"
          clearable
          :label="
            $t('forms.fields.federalState') +
            (isRequiredFederalState ? '*' : '')
          "
          :items="states"
          :item-text="`translations.${$i18n.locale}.name`"
          item-value="id"
          :loading="form.loading"
          :disabled="!isRequiredFederalState"
        />

        <v-text-field
          v-model="form.data.phone"
          :error-messages="errors.phone"
          :label="$t('forms.fields.phone') | required"
          :loading="form.loading"
        />

        <v-text-field
          v-model="form.data.email"
          :error-messages="errors.email"
          :label="$t('forms.fields.email') | required"
          :loading="form.loading"
        />

        <v-text-field
          v-model="form.data.website"
          :error-messages="errors.website"
          :label="$t('forms.fields.website') | required"
          @blur="formatWebsite"
          :loading="form.loading"
        />
      </v-col>
    </v-row>

    <v-row v-if="!isRequiredFederalState">
      <v-col cols="12" v-if="!isRequiredFederalState">
        <h3>{{ $t('forms.fields.furtherParticipationRequirements') }}</h3>
        <p>{{ $t('forms.fields.germanRelations') }}</p>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="form.data.is_foreign_branch"
          :label="$t('forms.fields.foreignBranch')"
          class="ml-2"
          dense
          :disabled="!!form.data.is_has_relations"
        />

        <v-checkbox
          v-model="form.data.is_foreign_representation"
          :label="$t('forms.fields.foreignRepresentation')"
          class="ml-2"
          dense
          :disabled="!!form.data.is_has_relations"
        />

        <v-checkbox
          v-model="form.data.is_has_relations"
          :label="$t('forms.fields.noRelations')"
          class="ml-2"
          dense
          :disabled="
            !!form.data.is_foreign_branch || !!form.data.is_foreign_representation
          "
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-if="form.data.is_foreign_branch || form.data.is_foreign_representation"
          class="pt-0"
          v-model="form.data.related_company"
          :label="$t('forms.fields.relatedCompany') | required"
          :loading="form.loading"
        />
        <p v-if="form.data.is_has_relations">
          {{ $t('forms.fields.noRelationsMessage') }}
        </p>
      </v-col>
    </v-row>

    <v-row
        class="mx-0 mb-2 px-3"
    >
      <v-btn
          v-if="!accreditation.attributes.accreditation_approved_at && accreditation_old.attributes.accreditation_approved_at"
          @click="$emit('cancel')"
          color="error"
          class="elevation-0"
      >
        {{ $t('common.cancel') }}
      </v-btn>

      <v-btn
          @click="handleSubmit"
          color="primary"
          class="elevation-0 ml-auto"
          :disabled="!isAvailableToSubmit"
      >
        {{ $t('forms.buttons.continue') }}
      </v-btn>
    </v-row>
    <accreditation-collision-modal ref="collision"></accreditation-collision-modal>
  </v-form>
</template>

<script>
import { GENDERS } from '@/enums/genders'
import { mapActions, mapGetters } from 'vuex'
import { required, email, url, minLength } from 'vuelidate/lib/validators'
import { addProtocolIfMissing } from '@/utilities/helpers/urls'
import validation from '@/mixins/validate'
import AccreditationCollisionModal from '@/components/exhibitor/Accreditation/AccreditationCollisionModal'
import {api} from "@/boot/api";

export default {
  name: 'CompanyAndContactDetails',
  components: {AccreditationCollisionModal},

  props: {
    accreditation: Object,
    accreditation_old: Object,
  },

  mixins: [validation],

  data() {
    return {
      form: {
        loading: false,

        customFieldsMap: {
          country: 'country_id',
        },

        data: {
          company: '',
          street: '',
          address_supplement: '',
          postalcode: '',
          city: '',
          country: '',
          federal_state: '',
          phone: '',
          email: '',
          website: '',
          related_company: '',
          is_foreign_branch: false,
          is_foreign_representation: false,
          is_has_relations: false,
        },
        validationRules: {
          company: {
            required,
          },
          street: {
            required,
          },
          postalcode: {},
          city: {
            required,
          },
          address_supplement: {},
          country: {
            required,
          },
          federal_state: {},
          phone: {
            required,
          },
          email: {
            required,
            email,
          },
          website: {
            required,
            url,
          },
          related_company: {},
          is_foreign_branch: {},
          is_foreign_representation: {},
          is_has_relations: {},
        },
        custom: {
          validationSubstitutions: {
            postalcode: {minLength: {length: 4},},
          },
        },
      },
    }
  },

  async created() {
    await this.fetchCountries()
    await this.fetchStates()

    this.setInitialFormData(this.accreditation.attributes)

    this.form.data.country = this.accreditation.relationships.country?.id
    this.form.data.federal_state = this.accreditation.relationships.federal_state?.id

    if (!this.accreditation_old.attributes.accreditation_approved_at) {
      await this.handleCheckCollision()
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('company', ['company', 'isCompanyFetched']),

    ...mapGetters('countries', ['countries', 'prioritizedCountries']),

    ...mapGetters('states', ['states']),

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },

    isRequiredPostalCode() {
      return (this.form.data.country === this.countries.find((el) => el.iso31662 === 'DE')?.id)
    },

    isRequiredFederalState() {
      return (this.form.data.country === this.countries.find((el) => el.iso31662 === 'DE')?.id)
    },

    isAvailableToSubmit() {
      if (this.form.data.is_has_relations) {
        return false
      }

      if (!this.isRequiredFederalState) {
        return (
          (this.form.data.is_foreign_representation ||
            this.form.data.is_foreign_branch) &&
          this.form.data.related_company
        )
      }

      return true
    },
  },

  methods: {
    ...mapActions('countries', ['fetchCountries']),

    ...mapActions('states', ['fetchStates']),

    formatWebsite({ target: { value } }) {
      this.form.data.website = addProtocolIfMissing(value)
    },

    setValidationRules() {
      if (this.isRequiredFederalState) {
        this.form.validationRules.federal_state = { required: required }
        this.form.validationRules.postalcode = { required: required, minLength: minLength(4) }
      } else {
        this.form.validationRules.federal_state = {}
        this.form.validationRules.postalcode = {}
      }
    },

    async handleCheckCollision() {
      let collision = (await api.post(`/accreditations/check`, {}))

      if (collision) {
        this.$refs.collision.show()
      }
    },

    async handleSubmit() {
      this.setValidationRules()

      this.$v.$touch()
      if (this.$v.$invalid) {
        if (this.errors.federal_state) {
          this.errors.federal_state = this.$t('forms.fields.federalStateRequired')
        }

        return
      }

      this.form.loading = true
      try {
        await this.$axios.post(`/api/accreditations`, {
          ...this.getFormData(),
          company_id: this.user.companyId,
          is_onboarding: true
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  watch: {
    'form.data.country'() {
      if (!this.isRequiredFederalState) {
        this.form.data.federal_state = null
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
