export default {
  settings: {
    dataAndSettings: 'Daten und Einstellungen',
    manageSettings: 'Verwalten Sie hier zentral Ihre Einstellungen und Unternehmensdaten.',
    sections: {
      userCredentials: {
        title: 'Benutzername & Passwort',
        subTitle: 'Hier können Sie Ihre persönlichen Zugangsdaten bearbeiten.',
        warning: 'Wenn Sie Ihre E-Mail-Adresse ändern, muss diese erneut verifiziert werden und ein Login mit der jetzigen E-Mail-Adresse ist nicht mehr möglich.',
      },
      companyData: {
        title: 'Unternehmensdaten',
        warning: 'Wenn Sie Ihre Unternehmensdaten ändern, müssen Sie auch eine neue Akkreditierungsanfrage stellen!'
      },
      accreditation: {
        title: 'Akkreditierung',
        description: 'Als akkreditiertes Unternehmen erhalten Sie die Berechtigung, Ihre Teilnahme an den Veranstaltungen des Auslandsmesseprogramms des Bundes sicher, verbindlich und komfortabel über diese Plattform zu buchen.',
        statuses: {
          alert: 'Bevor eine richtige Preisberechung angezeigt und die Anmeldung abgesendet werden kann, muss die Akkreditierung eingesendet und von uns freigegeben sein.',
          approved: 'Ihre Akkreditierung wurde genehmigt',
          carryOut: 'Ihre Akkreditierung ist noch nicht abgeschlossen',
          approvalPending: 'Ihre Akkreditierung steht noch aus',
          rejected: 'Ihre Akkreditierung wurde entzogen',
          undefined: 'Sie haben den Akkreditierungsprozess noch nicht gestartet.',
          button: {
            approved: 'Akkreditierung aktualisieren',
            carryOut: 'Akkreditierung durchführen',
            approvalPending: 'Akkreditierung aktualisieren',
            rejected: 'Akkreditierung aktualisieren',
          }
        },
      },
      notificationSettings: {
        title: 'Benachrichtigungseinstellungen',
        categories: {
          title: 'Über folgende Benachrichtigungen auch per E-Mail informieren.',
          processesToParticipation: 'Vorgänge zu meinen Messeteilnahmen',
          informationOnReservedFairs: 'Informationen und Hinweise zu vorgemerkten Messen',
          informationOnFederalFairProgram: 'Informationen zum Auslandsmesseprogram des Bundes',
          newFeaturesAndAnnouncements: 'Neue Funktionen und Funktionsankündigungen',
          accountAndSecurity: 'Konto und Sicherheit',
        },
        recipients: {
          title: 'Benachrichtigungen an diese E-Mail-Adressen senden:',
          addRecipient: 'Empfänger hinzufügen',
        },
      },
      profileOfInterest: {
        title: 'Interessenprofil',
        description: 'Welche Länder und welche Branchen sind für Ihr Unternehmen für eine Messeteilnahme interessant?',
      },
      assignedProjects: {
        title: 'Zugeordnete Projekte',
        description: 'Hier finden Sie eine Übersicht der Ihnen zugeordneten Projekte/Beteiligungen. Die Zuordnung der Projekte kann durch folgende Nutzer bearbeitet werden:'
      },
    }
  },
}
