export default {
  participation: {
    accreditation: {
      data: 'Accreditation data',
      viewData: 'View accreditation data',
      dataHint: 'Here you find the currently approved accreditation data of the exhibitor',
    },
    participations: 'Participations',
    registrationForJointCompanyExhibition: 'Registration for the German participation',
    registrationDeadlineIsRunning: 'Registration running',
    seeParentExhibitor: 'See parent exhibitor',
    configureRegistrationForm: {
      allowSubexhibitorCheckbox: 'Sub-exhibitors may be registered',
      allowSubexhibitor: 'Sub-exhibitors are generally not allowed to be added to an IZ booking. Please contact BAFA if necessary.',
      allowSubexhibitorStandSpace: 'Sub-exhibitors are generally allowed to be added to a stand space booking. Please contact BAFA if necessary.',
      save: 'Save',
      preview: 'Show preview',
      editRegistrationForm: 'Edit registration form',
      downloadSummary: 'Download registration form summary',
      price: 'Price',
      priceQ: 'Price €',
      priceQm: 'Price €/㎡',
      pricePerUnit: 'Price per unit',
      sizeOn: 'Size from',
      sizeUp: 'Size to',
      sizeFrom: 'Size from',
      sizeTo: 'Size to',
      sizeInSqm: 'Size in ㎡',
      partialPayer: 'Price 1st - 4th participation',
      fullPayer: 'Price from 5th participation',
      publicHand: 'Full price',
      priceSponsored: 'Price 1st - 4th participation',
      priceUnsponsored: 'Preis ab 5. Teilnahme',
      pricePublicsector: 'Full price',
      label: 'Label / Note',
      amount: 'Amount',
      add: 'Add',
      addFee: 'Add fee for main exhibitors',
      addPackage: 'Add package',
      designation: 'Service description',
      designation_en: 'Description (English)',
      designation_de: 'Description (German)',
      service_description_en: 'Service description (English)',
      service_description_de: 'Service description (German)',
      apply: 'Apply',
      cancel: 'Cancel',
      rejectWithMessageModal: {
        title: 'Message to the exhibitor',
        body: 'Why was this registration not accepted (Optional)',
        label: 'Write a short reason for the rejection, please do not add a signature (will be completed automatically)'
      },
      serviceDescription: {
        generalServiceDescription: 'General service description',
        generalServiceDescriptionPlaceholder: 'General service description. This text is displayed to the exhibitor during registration and in the booking confirmation as a service description.',
        descriptionWithContruction: 'Description for exhibition space incl. construction',
        descriptionWithoutContruction: 'Description for exhibition space without construction',
        serviceDescriptionWithContruction: 'Description for exhibition space incl. construction. This text is displayed to the exhibitor during registration and in the booking confirmation as a service description.',
        serviceDescriptionWithoutContruction: 'Description for exhibition space without construction. This text is displayed to the exhibitor during registration and in the booking confirmation as a service description.',
      },
      general: {
        title: 'General settings',
        space: 'Exhibition space',
        package: 'Package',
        minParticipants: 'Minimum number of participants',
        spaceMinSize: 'Minimum exhibition space (㎡)',
        spaceMaxSize: 'Maximum exhibition space (㎡)',
        spaceRangeError: 'This range is not correct for already created spaces',
        typesError: 'You need to choose at least one option',
        nameAlternative: 'Adjust trade fair name',
        nameAlternativeAlert: 'Please fill in only if required!',
        nameAlternativeClue: 'If you change and save the trade fair name, it will be changed everywhere on this platform from now on! For the standard name, please leave this field empty.'
      },
      exhibitionSpace: {
        conflictModalTitle: 'Conflict with Exhibition Spaces',
        conflictModalBody: 'The already created spaces in the "Exhibition Space"-Tab are in conflict with the entered max. or min. exhibition spaces. You can either adjust those spaces in the "Exhibition Space"-Tab and revisit this Step or reset the created spaces.',
        conflictModalReset: 'Reset exhibition spaces',
        conflictModalAdjust: 'Adjust exhibition spaces',
        standWithConstructionStand: 'Exhibition space options including stand construction:',
        standWithoutConstructionStand: 'Exhibition space options without stand construction:',
        alertDeleteSpace: 'Really delete this space?',
        addExhibitionSpace: 'Add exhibition space option',
        editExhibitionSpace: 'Edit exhibition space option',
        errorCollision: 'Conflict with other exhibition space options'
      },
      servicePackage: {
        title: 'Title',
        title_de: 'Title (German)',
        title_en: 'Title (English)',
        services: 'Services included',
        services_de: 'Services included (German)',
        services_en: 'Services included (English)',
        alertDeletePackage: 'Really delete this service package?',
        addServicePackage: 'Add service package',
        editServicePackage: 'Edit service package',
      },
      fees: {
        title: 'Fees',
        designation_en: 'Meaningful name for fee (EN)',
        designation_de: 'Meaningful name for fee (DE)',
        subExhibitorFee: 'Sub-exhibitor fee',
        perSubExhibitorFee: 'Fee per sub-exhibitor',
        perSubExhibitorFee_de: 'Fee per sub-exhibitor (German)',
        perSubExhibitorFee_en: 'Fee per sub-exhibitor (English)',
        perSubExhibitorIn: 'in € per sub-exhibito',
        perMainExhibitorFee: 'Fee per main exhibitor',
        perMainExhibitorIn: 'in € per main exhibitor',
        enterExternalFee: 'Additional sub-exhibitor fee of the organiser',
        furtherCompulsoryFee: 'Further compulsory fee',
        alertDeleteFee: 'Really delete this fee?',
        addServiceFees: 'Add fee',
        editServiceFees: 'Edit fee',
        coexhibitorFee: 'Organiser fee per sub-exhibitor',
        coexhibitorFeeTitle: 'Name of the fee',
        coexhibitorFeeDescription: 'Note/Description',
        coexhibitorFeeTitle_de: 'Name of the fee (German)',
        coexhibitorFeeDescription_de: 'Note/Description (German) - please note that this text will only be visible in the price information of the participation but not in the registration form itself.',
        coexhibitorFeeTitle_en: 'Name of the fee (English)',
        coexhibitorFeeDescription_en: 'Note/Description (English) - please note that this text will only be visible in the price information of the participation but not in the registration form itself.',
      },
      equipment: {
        title: 'Stand equipment and additional services',
        optional: 'Optional',
        optionalNotice: 'Optional: Here you can create additional positions that can be booked by exhibitors free of charge or for a fee.',
        previewNotice: 'Below you can see the preview of the input fields for exhibitors.',
        amountSelectable: 'Quantity specification possible',
        amountSelectableText: 'The exhibitor can enter the desired quantity for this item.',
        mandatorySelection: 'Mandatory selection',
        mandatoryBooking: 'Mandatory booking',
        mandatoryBookingMust: 'This position <u>must</u> be selected or booked by the exhibitor.',
        mandatoryBookingCan: 'This position <u>can</u> be selected or booked by the exhibitor.',
        multipleSelectionPossible: 'Multiple selection possible',
        multipleSelectionPossibleOnlyOnce: 'The exhibitor can book this position <u>only once</u> in the desired quantity.',
        multipleSelectionPossibleSeveralTimes: 'The exhibitor can book this position <u>several times</u> in the desired quantity.',
        additionalTextInput: 'Additional text input',
        additionalTextInputNotice: 'An additional indication by the issuer is required for this position, e.g. a name',
        additionalTextInputLabelling: 'Labelling of the input field',
        additionalTextInputLabelling_de: 'Labelling of the input field (German)',
        additionalTextInputLabelling_en: 'Labelling of the input field (English)',
        addAnotherOption: 'Add another option',
        alertDeleteOption: 'Really delete this option?',
        editEquipment: 'Edit stand equipment or additional service',
        addEquipment: 'Add stand equipment or additional service',
        label: 'Label',
        label_de: 'Label (German)',
        label_en: 'Label (English)',
        description: 'Description',
        description_de: 'Description (German)',
        description_en: 'Description (English)',
      },
      exhibitionArea: {
        title: 'Exhibition area',
        title_de: 'Exhibition area (German)',
        title_en: 'Exhibition area (English)',
        alertDeleteExhibitionArea: 'Really delete this exhibition area?',
        addExhibitionArea: 'Add exhibition area',
        editExhibitionArea: 'Edit exhibition area',
        warning: 'Optional: Exhibitors can choose <u>one</u> of the exhibition areas listed here for their desired stand placement.'
      }
    },
    contactData: {
      title: 'Exhibitor',
      declaration: 'We declare that including this registration, that',
      participatedLessThanFiveTimes: 'We hereby declare that, including this registration, we have not participated in the German joint stand at this trade fair more than four times since July 2021.',
      participatedMoreThanFourTimes: 'we have participated in the German joint stand at this trade fair more than four times since July 2021.',
      incorrectInformationAwareness: 'I am aware that false information may result in exclusion from further participation in the federal trade fair programme.',
      contactPerson: 'Contact person',
      companyData: 'Company data',
    },
    bookingTypeSelect: {
      title: 'Service Package or Exhibition Space',
      spaceLabel: 'I wish to book an exhibition space including or without stand construction',
      packageLabel: 'I would like to book one of the offered participation packages',
    },
    exhibitionSpace: {
      title: 'Exhibition space',
      withStandConstruction: 'With stand construction',
      withoutStandConstruction: 'Without stand construction',
      spacePerWithStandConstruction: 'exhibition space with stand construction',
      spacePerWithoutStandConstruction: 'exhibition space without stand construction',
      standSpacePrice: 'Exhibition space price',
      hallSpace: 'Exhibition space',
      openSpace: 'Open space',
      costOverview: 'Cost overview',
      sizeError: 'Please enter a value of exhibition area that corresponds to the specifications.',
      spaceRangeError: 'The spaces ranges must be without gaps',
      allowedCoexhibitor: 'Subexhibitor allowed'
    },
    servicePackage: {
      title: 'Service package',
      thereIsNoServicePackage: 'There is no service package specified',
    },
    exhibitionArea: {
      title: 'Exhibition area',
      areasChosen: 'no area chosen | 1 area chosen | {amount} areas chosen',
      thereIsNoExhibitionArea: 'There is no exhibition area specified',
    },
    exhibitionGoods: {
      title: 'Exhibition goods',
      addGoodsSubtitle: 'Create new exhibition good with measurements and weight',
      exhibitsSpecified: 'no exhibits specified | 1 exhibit specified | {amount} exhibits specified',
    },
    equipment: {
      title: 'Stand equipment and additional services',
      servicesSelected: 'no services selected | 1 service selected | {amount} services selected',
      badges: 'Badges',
      multiselectable: 'multiselectable',
      specification: 'Specification',
      quantity: 'Quantity',
      unit: 'Unit',
      description: 'Description',
      unitPrice: 'Unit price',
      totalPrice: 'Total price',
      participantName: 'Participant name',
      moreItems: 'More items',
    },
    subExhibitors: {
      title: 'Sub-exhibitor',
      viewSubExhibitors: 'View sub-exhibitors',
      addSubExhibitors: 'Add sub-exhibitors',
      addSubExhibitorsSubtitle: 'Create a new sub-exhibitor and contact person',
      subExhibitor: 'no sub-exhibitors | 1 sub-exhibitor | {amount} sub-exhibitors',
      contactPerson: 'Contact person',
      exhibitionGoods: 'Exhibition goods',
      note: 'Wherever applicable, the General Conditions also apply to any sub-exhibitors. Any participation of sub-exhibitors is subject to financial charges. Even after admission of a sub-exhibitor, contractual relations of the implementing company continue to exist only with the exhibitor. The exhibitor is liable for the actions of its sub-contractors in the same way as for its own actions.<br><br><b>Services for sub-exhibitors</b><br>• Use of the information stand of the German participation<br>• Accompanying services corresponding to those for the exhibitors.',
    },
    financialSupport: {
      title: 'Double funding / participation by the public sector',
      declaration: {
        fromAccreditation: 'This information was taken from your accreditation and cannot be changed here:',
        title: 'Declaration on double funding or the participation of the public sector',
        list: {
          notReceiveFunding: 'We declare that we do not receive any institutional funding from public funds.',
          notReceiveFurtherPublicFunds: 'We declare that we will not receive any further public funds from project funding for participation in this trade fair.',
          companyIsNotAuthority: 'We declare that our company is not a federal, state or local authority, state funding institute or other legal entity under public law.',
          noReligionInterestInCompany: 'We declare that no religious community (s) or legal person (s) under public law, individually or collectively, directly or indirectly, have a majority interest in our company.',
        },
      },
    },
    costOverview: {
      title: 'Cost overview',
      totalCosts: 'Total costs',
      position: 'Item',
      participationPrice: 'Participation Price and Fees',
      fees: {
        title: 'Fees',
        hallSpace: 'Exhibition space',
        packageFee: 'Package fee',
        registrationFee: 'Registration fee',
        subExhibitorRegistrationFee: 'Sub-exhibitor registration fee',
        subExhibitorMediaEntry: 'Sub-exhibitor media entry',
        subExhibitorMediaEntry2: 'Sub-exhibitor media entry 2',
      },
      pricesDontIncludeTaxes: 'The prices quoted do not include the statutory German sales tax and, if applicable, statutory foreign taxes (e.g. VAT, sales tax) on the services of the implementing company to the exhibitor. The participation fees only cover part of the total costs of the services.<b> The obligation to pay 20% of the stated total costs arises with this registration.</b> This amount is due upon receipt of a corresponding down payment invoice. The amount stated in the down payment must be transferred to the account specified in the down payment invoice. Upon allocation of the space, the obligation to pay the remaining amount arises. This is due immediately upon receipt of the final invoice. The amount shown on the final invoice must be transferred to the account specified on the final invoice.<br><br>The cost calculation is based on your following declaration of double funding or participation by the public sector:<br>',
      noInstitutional: '•	We declare that we do not receive any institutional funding from public funds.<br>',
      noAuthority: '•	We declare that our company is not a federal, state or local authority, state funding institute or other legal entity under public law.<br>',
      noReligion: '•	We declare that no religious community or legal person under public law, individually or collectively, directly or indirectly, have a majority interest in our company.<br>',
      lessThenFive: '• We hereby declare that, including this registration, we have not participated in the German joint stand at this trade fair more than four times since July 2021.<br>',
      noFurtherFunds: '• We declare that we will not receive any further public funds from project funding for participation in this trade fair.<br>',
      furtherFunds: '• We declare that we will receive public funds from project funding for participation in this trade fair.<br>',
      moreThenFour: '• We hereby declare that, including this registration, we have participated in the German joint stand at this trade fair more than four times since July 2021.<br>',
      institutional: '•	We declare that we receive institutional funding from public funds.<br>',
      authority: '•	We declare that our company is a federal, state or local authority, state funding institute or other legal entity under public law.<br>',
      religion: '•	We declare that a religious community or legal person under public law, individually or collectively, directly or indirectly, has a majority interest in our company.<br>',
      waiver: 'Waiving individual company specific or general services shall not constitute a claim for a reduction of the participation fee. Under no circumstances may uniform design elements be foregone.'
    },
    billingAddress: {
      title: 'Billing address',
      invoiceAddress: 'Invoice address',
      company: 'Company or organisation',
      responsible: 'Department or contact person',
      email: 'E-mail',
      street: 'Street and house number',
      postalcode: 'Postal code',
      city: 'City',
      country: 'Country',
    },
    termsAndConditions: {
      title: 'Conditions of participation',
      list: {
        recognizingParticipationConditionsConfirmation: 'We hereby legally confirm that we accept the Conditions of Participation.',
        specialParticipationConditions: 'Special Conditions of Participation (BTB) - {tradeFairName}',
        undertakingOnlyAllowedProducts: 'We hereby legally confirm that we accept the General Conditions of Participation (ATB).',
      },
    },
    checkAndSend: {
      title: 'Check and send',
      error: 'Please complete all other steps',
      bmel: "<b>German products</b><br>In the official German participation in the trade fair, only German products may be present, displayed and offered for sale as exhibits. The exhibitor in question must remove, without delay, foreign products he brought with him in breach of this rule from the stand at his own expense. For each case of non-removal the exhibitor shall be liable to pay a contractual penalty of EUR 250 and EUR 25 respectively per bottle in the case of beverages that must be paid immediately.<br><br>Should the exhibitor still refuse to remove the foreign products, the exhibition director shall order the removal at the exhibitor's expense. Exhibitors who fail to comply with the exhibition director's request to remove specific exhibits from the stand that do not meet the conditions for granting aid will no longer be allowed to take part in official joint exhibition stands staged by the Federal Ministry of Food and Agriculture in the future.",
      message: 'Additional message and hints',
      registrationRules: {
        registrationWillBeBindingIf: 'We hereby legally confirm:',
        reviewCarriedOutByCompany: 'This registration is binding in accordance with the General Conditions of Participation of the Federal Government of Germany in trade fairs and exhibitions abroad (ATB), which I previously confirmed.',
        paidDownPaymentInvoice: 'The registration creates a binding commitment for me as an exhibitor regardless of the admission. Any requirements or reservations imposed by the exhibitor during the registration process are inadmissible and void.',
      },
      payment: {
        totalCosts: 'Total costs / your participation fee',
        plusTaxes: 'plus statutory domestic and foreign taxes',
        taxesDescription: 'We have noted and acknowledged the General Conditions of Participation (ATB). The ATB can be accessed at www.german-pavilion.com/ATB.<br><br>We declare, that insolvency proceedings have not been filed or opened for our assets or that we have not issued or are obliged to make a statutory declaration in accordance with sec. 802c Civil Code of Civil Procedure (ZPO) or sec. 384 of the German Tax Code (AO) 1977.'
      },
      submitSuccessModal: {
        title: 'Thank you for your registration.',
        message: 'Your data has been submitted to the implementation company to be verified. You will also receive a confirmation e-mail.',
      }
    },
    registrations: 'Registrations',
    confirmed: 'Confirmed Registrations',
    registrationsInTotal: 'registrations in total',
    unconfirmed: 'unconfirmed',
    company: 'Company',
    haUa: 'HA/UA',
    ha: 'HA',
    ua: 'UA',
    contactPerson: 'Contact person',
    registeredOn: 'Registered on',
    registrationConfirmed: 'Registration confirmed',
    coExhibitor: 'Co-exhibitor',
    coExhibitors: 'Co-exhibitors',
    booking: 'Booking',
    status: 'Status',
    changed: 'Changed',
    fullPayer: 'Price from 5th participation',
    addressAndContactDetails: 'Address and contact details',
    street: "Street",
    postcode: "Postcode",
    city: "City",
    country: "Country",
    federalState: "Federal State",
    fax: 'Fax',
    email: 'E-Mail',
    website: 'Website',
    contactSalutation: 'Contact Person Salutation',
    contactFirstname: 'Contact Person Firstname',
    contactSurname: 'Contact Person Surname',
    contactPhone: 'Contact Person Phone Number',
    contactFax: 'Contact Person Fax',
    contactEmail: 'Contact Person E-Mail',
    companyName: 'Company Name',
    billingaddressCompany: 'Billing Address Company',
    billingaddressPerson: 'Billing Department or contact Person',
    billingaddressEmail: 'Billing E-Mail address',
    billingaddressStreet: 'Billing Address Street',
    billingaddressPostcode: 'Billing Address Postcode',
    billingaddressCity: 'Billing Address City',
    billingaddressCountry: 'Billing Address Country',
    registeredConfirmed: 'Registration confirmed',
    subExhibitor: 'Sub-exhibitor',
    parentExhibitor: 'Main exhibitor',
    bookedSpace: 'Booked Space ㎡',
    bookedType: 'Booking Type',
    bookedService: 'Booked Service',
    bookedAdditionalServices: 'Stand equipment and additional services',
    bookedGoods: 'Exhibition goods',
    bookedAreaTitle: 'Exhibition Area',
    standConstructionIncluded: 'Stand Construction Included',
    pricingModel: 'Pricing Model',
    additionNote: 'Additional note from exhibitor',
    bookedExhibitionSpace: 'Booked exhibition space',
    standEquipmentAndAdditionalServices: 'Stand equipment and additional services',
    phone: 'Phone',
    expressionOfInterest: 'Expression of interest',
    signUp: 'Registration',
    actionsByProjectManager: {
      confirmed: 'Registration confirmed by {manager} (project manager)',
      cancelled: 'Registration cancelled by {manager} ({role})',
      rejected: 'Registration rejected by {manager} (project manager)',
    },
    projectManager: 'project manager',
    notificationOfTheParticipant: 'Notification from the exhibitor',
    takeIntoAccountWhenRegistering: 'Please take this, this and that into account when registering. Thanks very much!',
    editSteps: {
      template: {
        template: 'Template',
        apply: 'Apply template',
        acceptMessage: 'When you apply the template, all previous settings are discarded. Would you like to continue?'
      },
      DynAMPConfiguration: {
        title: 'Loading Dyn AMP',
        configurationReceived: 'Configuration from Dyn AMP received',
        receivingConfiguration: 'Receiving Dyn AMP configuration',
      },
      configurationRegistration: {
        title: 'Configure',
        text: 'Please adapt the registration form and the possible booking options below. To do this, you can first adopt one of the existing templates. You can then adjust the individual settings and conditions.',
      },
      projectManagement: {
        title: 'Project management',
        contactPersonInRegistrationForm: 'Contact person in the registration form',
      },
      deadline: {
        title: 'Deadline',
        registrationDeadline: 'Registration deadline',
      },
      approvalAndPublication: {
        title: 'Approval and publication',
        examinationAndApprovalIsRequired: 'In order to be able to start the online registration, the examination and approval by BMWK/BAFA resp. BMWK is required.',
        approvalGranted: 'Approval by BMWK/BAFA resp. BMEL has been granted',
        disablingFormAttention: 'Attention: The form can no longer be edited as soon as "Open Registration" is clicked.',
      },
    },
    stepsIsErrored: 'Please complete this step',
    supervisorPrintHint: 'Registration is done via the registration forms previously used. Exhibitor registrations can therefore not be viewed here.',
    link: {
      useRegistrationLink: 'Please use the following URL to link directly to the online registration for this event.',
      onlineRegistrationLink: 'Link to online registration',
    },
    registrationFormPreview: 'Preview of the registration form',
    registrationFormAlertTitle: 'Cost overview in the preview no reference',
    registrationFormAlert: 'The cost overview is currently not fully displayed in the preview. However, all fees, stand equipment and additional services that you have previously created will be displayed to the exhibitor in the live registration form.',
    actions: {
      view: 'View registration',
      returnForProcessing: 'Return registration for processing',
      open: 'Open registration',
      openDisclaimer: 'The registration can only be opened once the exact trade fair period for the trade fair is known, but your changes to the form will already be saved.',
      openDisclaimerDeadline: 'The deadline can only be set once the exact trade fair period for the trade fair is known, but your changes to the form will already be saved.',
      openDisclaimerForm: 'The exact period of the trade fair is not yet known, but your changes to the form will already be saved.',
      edit: 'Edit registration',
      confirm: 'Confirm registration',
      cancel: 'Cancel registration',
      reject: 'Reject registration',
      saveAsPDF: 'Save as PDF',
      showForm: 'Show registration form',
    },
    statuses: {
      inPreparation: 'Registration in preparation',
    },
    funding: {
      funded: 'Funded',
      notFunded: 'Not funded',
      publicSector: 'Public sector',
    },
    tradeFairNotSelected: 'You have not selected a trade fair show',
    noEndDate: 'The exact fair period is not yet known, therefore the fair cannot be configured yet.',
    participationSubmitted: 'Participation has been submitted',
    registrationIsNotCarriedOutOnline: 'Registration for this trade fair participation is not carried out via online registration. The registration documents were created with DynAMP and can be downloaded from the AUMA website:',
    firstCompleteSetupInAUMA: 'In order to be able to set up the online registration for this event, please first complete the setup in AUMA\'s DynAMP. When doing so, select that the registration process is intended as online registration.',
    onlineRegistration: 'Online registration',
    openOnlineRegistration: 'Registration form',
    deadlineStatuses: {
      running: 'The online registration period is currently running. Registration deadline: {date}',
      expired: 'The registration deadline has expired. Registration deadline: {date}',
    },
    fillExhibitorFirst: 'Participation is not created yet. Please, fill the Exhibitors form first.',
    foreignRepresentation: 'Foreign representation',
    foreignBranch: 'Foreign Branch',
    relatedCompany: 'Related German Company',
    notifyHeader: 'Notify interested exhibitors',
    notifyText: 'Send an e-mail to all companies interested in this trade fair and inform them that registration is now possible. The e-mail will also contain the link to the registration.',
    notifyButton: 'Send E-mail to interested companies',
    notifyCount: '{amount} Interested companies were notified at at {time} (UTC) on {date}.',
    totalSpace: 'Total booked exhibition space'
  },
}
