<template>
  <li>
    <router-link :to="{ name: 'admin.users', query: { user: user.id } }">
    {{ user.attributes.email }} {{ user.attributes.full_name ? `(${user.attributes.full_name})` : '' }}
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'UserCompany',

  props: {
    user: {
      type: Object,
      required: true,
    }
  },
}
</script>
