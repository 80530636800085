export default {
  accreditation: {
    accreditations: 'Accreditations',
    checkAndApproveNew: 'Check and approve new accreditations.',
    title: 'Accreditation',
    notAvailable: 'not available',
    description: {
      authorizedToBookViaPlatform: 'As an accredited company, you are authorized to book your participation in the events of the federal foreign trade fair program securely, bindingly and conveniently via this platform.',
      companyIdentityIsChecked: 'To do this, the identity of your company is checked. In addition, it will be checked whether your company is entitled to participate in the federal foreign trade fair participations.',
    },
    statusTexts: {
      approvalPending: 'Your accreditation request has been received and is currently being reviewed. You will be informed by e-mail as soon as accreditation has been granted.',
      approved: 'Your company\'s accreditation was granted on {date}. You can update your submitted data below. Updates will only be activated after a new check.',
      rejected: 'Your company\'s accreditation was rejected on {date}. You can update your submitted data below. Updates will only be activated after a new check.',
    },
    hints: {
      enterCompanyDetails: 'Enter company details',
      uploadExcerpt: 'Upload excerpt from the commercial register or business registration',
      publicSectorParticipationInformation: 'Information on the participation of the public sector',
    },
    steps: {
      companyData: {
        title: 'Company data',
        subTitle: 'Company and contact details',
      },
      registerExcerpt: {
        title: 'Register excerpt',
        subTitle: 'Register excerpt',
        notice: 'For foreign branches or representative offices, it is only mandatory to provide a VAT ID number',
        description: 'Please upload your current licence from the commercial register as a PDF document here. Alternatively, you can also submit your business registration or other suitable proofs of your organization here.',
      },
      publicParticipation: {
        title: 'Public participation',
        subTitle: 'Declaration on the participation of the public sector',
        hintTitle: 'Please respond to all 3 statements.',
        hintTitleRed: 'Please check the following explanations carefully, as they are relevant for determining your conditions of participation.',
        declarations: {
          publicSectorInvolvement: 'We do not receive any institutional funding from public funds.',
          publicSectorInstitution: 'Our company is not a federal, state or local authority, state development institute or other legal entity under public law.',
          publicSectorMajority: 'I / We declare that no religious community (s) or legal person (s) under public law, individually or collectively, directly or indirectly, have a majority interest in my / our company',
        },
      },
      conditions: {
        title: 'Conditions',
        recognizeConditionsForParticipation: {
          label: 'I / we hereby confirm in a legally binding manner that we recognize the general and special conditions for participation of the federal government in trade fairs and exhibitions abroad.',
          link: 'General Conditions of Participation for Federal Participation in Trade Fairs and Exhibitions Abroad (ATB)',
        },
        dataProtectionDeclaration: {
          label: 'I / we hereby confirm in a legally binding manner that we have taken note of the data protection declaration for participation by the federal government in trade fairs and exhibitions abroad and I / we agree to it.',
          link: 'Data protection declaration',
        },
      },
      complete: {
        title: 'Complete',
        subTitle: 'Submit accreditation',
        description: 'Submit your data now. It usually takes 1-2 working days to process your accreditation. You will be notified by email.',
        requiredVerification: 'Email verification required',
        tip: 'If you have already confirmed your email address and the button is still greyed out, please log out and log back in to fix the problem.',
        successModalTitle: 'Accreditation submitted',
        successModalMessage: 'Your accreditation request has been received and is currently being reviewed. You will be informed by e-mail as soon as accreditation has been granted. This usually takes one to two days',
      },
    },
    status: 'Accreditation status',
    registeredSince: 'Registered since',
    grantedOn: 'Accreditation granted on',
    revokedOn: 'Accreditation revoked on',
    underPublicLaw: {
      publicSectorInvolvement:'Authority or institution is receiving institutional funding from public funds',
      publicSectorInstitution:'Authority or institution is under public law',
      publicSectorMajority:'Involvement of religious communities or legal entities under public law'
    },
    accreditationApproved: 'Accreditation has been approved',
    accreditationRejected: 'Accreditation has been rejected',
    alert: 'If you click on "continue" in the field below, you will start a new accreditation request.',
    infoSponsored: 'Click on the checkbox if the statement applies to your company.<br>Please note: In order to receive reduced participation fees, all three declarations must generally apply to your company.',
    infoSponsoredInstitutionalOrPublic: 'Institutional funding means grants to cover all or an unspecified portion of a grantees expenses. In practice, institutional funding is like a kind of permanent obligation for the public sector. <b>If this does not apply</b> to your business, chose "Applies".',
    infoSectorMajority: 'If no public money is used to finance your trade fair participation in the foreign trade fair program, you can confirm this statement by clicking "Applies"',
    vatIdRequired: 'Please enter VAT-ID here',
  },
}
