<template>
  <div>
    <v-container class="pa-9" fluid>
      <v-card class="elevation-1 rounded-0">
        <v-card-title>
          <h4 class="primary--text">{{ $t('users.manageUserAccess') }}</h4>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn @click="$refs.invite.show()" class="ml-auto" icon>
                <v-icon size="20" color="secondary"> fal fa-user-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />

        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="5"
          class="elevation-1"
          :loading="form.loading"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
          item-key="id"
        >
          <template v-slot:item.last_name="{ item }">
            {{ item.attributes.full_name }}
          </template>

          <template v-slot:item.email="{ item }">
            {{ item.attributes.email }}
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ item.attributes.created_at | dateTimeByLocale }}
          </template>

          <template v-slot:item.updated_at="{ item }">
            {{ item.attributes.updated_at | dateTimeByLocale }}
          </template>

          <template v-slot:item.confirmed_at="{ item }">
            <v-icon
              :class="!item.included.confirmed_at ? 'red--text' : 'green--text'"
              size="10"
            >
              fas fa-circle
            </v-icon>
          </template>

          <template v-slot:item.delete="{ item }">
            <v-btn :disabled="!item.included.can_delete" color="secondary" icon @click="$refs.delete.show(); itemToDelete = item">
              <v-icon size="15">fa-solid fa-user-slash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <company-invite-modal :company="company" ref="invite" @submit="load"></company-invite-modal>
    <confirmation-modal
        ref="delete"
        @confirm="handleDelete"
    >
      {{ $t('common.deleteConfirmation', { item: itemToDelete?.attributes?.full_name }) }}
    </confirmation-modal>
  </div>
</template>

<script>
import CompanyInviteModal from '@/components/exhibitor/Company/CompanyInviteModal'
import {mapActions, mapGetters} from 'vuex'
import ConfirmationModal from "@/components/base/ConfirmationModal.vue";

export default {
  name: 'ExhibitorUsers',

  components: {
    ConfirmationModal,
    CompanyInviteModal
  },

  data() {
    return {
      options: {},

      form: {
        loading: false,
      },

      users: [],
      itemToDelete: null,
    }
  },

  computed: {
    ...mapGetters('company', ['company']),

    headers() {
      return [
        {
            text: this.$t('forms.fields.name'),
            align: 'start',
            sortable: true,
            value: 'last_name'
        },
        {
            text: this.$t('forms.fields.email'),
            value: 'email',
            sortable: true
        },
        {
            text: this.$t('forms.fields.created'),
            value: 'created_at',
            sortable: true
        },
        {
            text: this.$t('forms.fields.lastUpdated'),
            value: 'updated_at',
            sortable: true
        },
        {
            text: this.$t('company.confirmed'),
            value: 'confirmed_at',
            sortable: false
        },
        {
            text: this.$t('users.actions.delete'),
            value: 'delete',
            sortable: false
        },
      ]
    }
  },

  async created() {
    await this.fetchCompany()

    this.load()
  },

  methods: {
    ...mapActions('company', [
      'fetchCompany',
    ]),

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get(`/api/companies/${this.company.companyId}/users`, {
        params: {
          'languages': '*',
          'include': [],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.users = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    async handleDelete() {
      this.form.loading = true

      await this.$axios.delete(`/api/companies/${this.company.companyId}/users/${this.itemToDelete.id}`, {})

      this.load()
    }
  },

  watch: {
    'options'() {
      if (this.company.companyId) {
        this.load()
      }
    },
  },
}
</script>
