export default {
  users: {
    user: 'User',
    users: 'Users',
    manageUserAccess: 'Manage user access',
    manageUserTypesAndAccess: 'Manage user types and access.',
    type: 'User type',
    name: 'Name',
    organizers: 'Organizations',
    belongsToAssociation: 'This user belongs to association:',
    belongsToCompany: 'This user belongs to implementation company',
    canManageOtherUsersAndPMs:
      'Can manage other users and project managers for trade fairs',
    loginData: 'Login data',
    confirmedEmailAddress: 'Confirmed email address',
    emailAddressNotConfirmed: 'Email address is not confirmed',
    roles: {
      exhibitor: 'Exhibitor',
      association: 'Association',
      organizer: 'Organizer administrator',
      administrator: 'Admin',
      supervisor: 'Federal Ministries / AUMA',
      project_manager: 'Project manager',
    },
    actions: {
      create: 'Create user',
      activate: 'Activate user',
      deactivate: 'Deactivate user',
      delete: 'Delete user',
    },
    userManagement: {
      assignedTo: 'Assigned',
      title: 'User management',
      description: 'Manage login data and authorizations, project managers',
      active: 'Active',
      assignedProjects: 'Assigned projects',
      chooseProject: 'Choose project',
      admin: 'Admin',
      canManage: 'Can manage users and project managers',
      emailConfirmedAt: 'Email was confirmed at:', 
      actions: {
        createUser: 'Create user',
        activateUser: 'Activate user',
        deactivateUser: 'Deactivate user',
        deleteUser: 'Delete user',
        resendConfirmationEmail: 'Resend confirmation email',
      },
    },
  },
}
