<template>
  <div>
    <v-card
        v-if="!excludeHeader"
        class="elevation-1 rounded-0"
    >
      <v-card-title>
        <div>
          <h5>
            {{ $t('participation.registrationForJointCompanyExhibition', lang) }}
          </h5>
          <h3 class="primary--text" v-html="fair.attributes.name_display"></h3>
        </div>
        <v-chip
            v-if="isRegistrationRunning"
            class="secondary white--text ml-auto"
            small
        >
          {{ $t('participation.registrationDeadlineIsRunning', lang) }}
        </v-chip>
      </v-card-title>

      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="3">
            <small>{{ $t('tradeFair.date', lang) }}</small>
            <p>
              {{ fair.attributes.start_date_display[$i18n.locale] }} -
              {{ fair.attributes.end_date | dateByLocale }}
            </p>
          </v-col>

          <v-col cols="3">
            <small>
              {{
                $t('tradeFair.location', lang)
              }}
            </small>
            <p>{{ fair.attributes.city }}, {{ fair.relationships.country.translations[$i18n.locale].country }}</p>
          </v-col>

          <v-col cols="3">
            <small>
              {{
                $t('tradeFair.deadline', lang)
              }}
            </small>
            <p v-if="fair.attributes.registration_deadline">
              {{ fair.attributes.registration_deadline | dateByLocale }}
            </p>
            <p v-else>
              {{
                $t('tables.tradeFair.notYetKnown', lang)
              }}
            </p>
          </v-col>

          <v-col cols="3"/>

          <v-col cols="12">
            <v-divider/>
          </v-col>

          <v-col cols="6">
            <v-row>
              <v-col cols="6">
                <small>
                  {{
                    $t('tradeFair.executionCompany', lang)
                  }}
                </small>

                <p>
                  {{ fair.relationships.organizer.name }}
                  <br/>
                  {{ fair.relationships.organizer.address }}
                  <br/>
                  {{ fair.relationships.organizer.postal_code }}
                  {{ fair.relationships.organizer.city }}
                </p>
              </v-col>

              <v-col cols="6">
                <small>
                  {{
                    $t('tradeFair.projectManager', lang)
                  }}
                </small>
                <p>
                  {{ fair.attributes.contact_name }}
                  <br/>
                  {{ fair.attributes.contact_email }}
                  <br/>
                  {{ $t('forms.fields.tel', lang) }}.
                  {{ fair.attributes.contact_phone }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="3">
            <p>
              <small>
                {{
                  $t('tradeFair.organizer', lang)
                }}
              </small>
            </p>
            <img
                v-if="fair.attributes.sponsor === sponsors.BMWi"
                :src="`/img/logos/bmwi_${$i18n.locale}.svg`"
                alt="BMWK"
                class="logo logo-bmwi"
            />
            <img
                v-if="fair.attributes.sponsor === sponsors.BMEL"
                :src="`/img/logos/bmwel_${$i18n.locale}.svg`"
                alt="BMEL"
                class="logo logo-bmel"
            />
          </v-col>

          <v-col cols="3">
            <p>
              <small>
                {{
                  $t('common.inCooperationWith', lang)
                }}
              </small>
            </p>
            <img
                :src="`/img/logos/auma_${$i18n.locale}.svg`"
                alt="AUMA"
                class="logo logo-auma"
            />
          </v-col>
        </v-row>

        <v-row
            v-if="
            company && (!company.attributes.accreditation_approved_at || !company.attributes.accreditation_request_at || company.attributes.accreditation_rejected_at)
          "
        >
          <v-col cols="12" v-if="!isPreviewMode">
            <v-btn
                v-if="
                (!company.attributes.accreditation_approved_at &&
                !company.attributes.accreditation_request_at)
                || company.attributes.accreditation_rejected_at
              "
                :to="{
                name: 'exhibitor.accreditation',
                params: { id: fair.attributes.id },
              }"
                class="secondary elevation-0 mr-5"
            >
              {{
                $t('settings.sections.accreditation.statuses.button.carryOut')
              }}
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="!isPreviewMode">
            <v-alert dense outlined type="warning">
              {{
                $t(`settings.sections.accreditation.statuses.${company.attributes.status}`)
              }}<br/>
              <small v-if="company.attributes.accreditation_request_at && !company.attributes.accreditation_rejected_at">
                {{ $t('accreditation.statusTexts.approvalPending') }}<br/>
              </small>
              <small>
                {{ $t('settings.sections.accreditation.statuses.alert') }}
              </small>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import participationMode from '@/mixins/participationMode'
import {SPONSORS} from '@/enums/sponsors'
import {mapGetters} from 'vuex'

export default {
  name: 'ApplicationHeader',

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },


    mode: {
      type: String,
    },

    excludeHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mixins: [participationMode],

  data() {
    return {
      form: {
        loading: false,
      },

      sponsors: {
        BMWi: SPONSORS.BMWI,
        BMEL: SPONSORS.BMEL,
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    isRegistrationRunning() {
      return this.$moment().isBefore(this.fair.attributes.registration_deadline) && this.fair.attributes.flag_registration_is_open
    },
  },
}
</script>

<style>
.logo {
  max-height: 80px;
  width: auto;
  display: block;
}

.table-packages tr td {
  padding: 1.25rem 0.5rem !important;
  border-top: 1px solid #e9e9e9;
  vertical-align: top;
}

.stepper-abstract {
  display: inline-block;
  right: 0;
  padding-right: 2rem;
  position: absolute;
}
</style>
