<template>
  <v-form>
    <v-row>
      <slot name="header" />

      <v-col cols="6" class="pr-10">
        <v-text-field
          v-model="form.data.company"
          :error-messages="errors.company"
          :label="$t('forms.fields.company') | required"
          :loading="$wait.is(loaders.fetchCompany)"
          required
        />

        <v-text-field
          v-model="form.data.street"
          :error-messages="errors.street"
          :label="$t('forms.fields.street') | required"
          :loading="$wait.is(loaders.fetchCompany)"
        />

        <v-text-field
          v-model="form.data.postalcode"
          :error-messages="errors.postalcode"
          :label="$t('forms.fields.postalcode') +
            (isRequiredPostalCode ? '*' : '')"
          :loading="$wait.is(loaders.fetchCompany)"
        />

        <v-text-field
          v-model="form.data.city"
          :error-messages="errors.city"
          :label="$t('forms.fields.city') | required"
          :loading="$wait.is(loaders.fetchCompany)"
        />

        <v-text-field
          v-model="form.data.addressSupplement"
          :error-messages="errors.addressSupplement"
          :label="$t('forms.fields.addresssupplement')"
          :loading="$wait.is(loaders.fetchCompany)"
        />
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="form.data.country"
          :error-messages="errors.country"
          :label="$t('forms.fields.country') | required"
          :items="prioritizedCountries"
          :item-text="`translations.${$i18n.locale}.country`"
          item-value="id"
          :loading="$wait.is([loaders.fetchCompany, loaders.fetchCountries])"
          :disabled="accreditationOld.countryId && !!accreditationOld.accreditationApprovedAt"
        />

        <v-select
          v-model="form.data.federalState"
          :error-messages="errors.federalState"
          :label="
            $t('forms.fields.federalState') +
            (isRequiredFederalState ? '*' : '')
          "
          :items="statesList"
          :item-text="`translations.${$i18n.locale}.name`"
          item-value="id"
          :loading="$wait.is([loaders.fetchCompany, loaders.fetchStates])"
          :disabled="!isRequiredFederalState"
        />

        <v-text-field
          v-model="form.data.phone"
          :error-messages="errors.phone"
          :label="$t('forms.fields.phone') | required"
          :loading="$wait.is(loaders.fetchCompany)"
        />

        <v-text-field
          v-model="form.data.email"
          :error-messages="errors.email"
          :label="$t('forms.fields.email') | required"
          :loading="$wait.is(loaders.fetchCompany)"
        />

        <v-text-field
          v-model="form.data.website"
          :error-messages="errors.website"
          :label="$t('forms.fields.website') | required"
          @blur="formatWebsite"
          :loading="$wait.is(loaders.fetchCompany)"
        />
      </v-col>
    </v-row>

    <v-row v-if="!isAccreditation">
      <v-col cols="12">
        <h3 class="text-center">
          {{ $t('forms.fields.contactTradeFairParticipation') }}
        </h3>
      </v-col>
      <v-col md="2" xl="1">
        <v-select
            v-model="form.data.contactGender"
            :items="genders"
            item-value="type"
            item-text="label"
            :label="$t('forms.fields.contactGender')"
            :error-messages="errors.contactGender"
            class="rounded-0"
        />
      </v-col>
      <v-col md="5" xl="2">
        <v-text-field
          v-model="form.data.contactFirstName"
          :loading="$wait.is(loaders.fetchCompany)"
          :label="$t('forms.fields.firstName')"
          :error-messages="errors.contactFirstName"
        >
        </v-text-field>
      </v-col>
      <v-col md="5" xl="2">
        <v-text-field
          v-model="form.data.contactLastName"
          :loading="$wait.is(loaders.fetchCompany)"
          :label="$t('forms.fields.lastName')"
          :error-messages="errors.contactLastName"
        >
        </v-text-field>
      </v-col>
      <v-col md="6" xl="3">
        <v-text-field
          v-model="form.data.contactEmail"
          :loading="$wait.is(loaders.fetchCompany)"
          :label="$t('forms.fields.email')"
          :error-messages="errors.contactEmail"
        >
        </v-text-field>
      </v-col>
      <v-col md="6" xl="3">
        <v-text-field
          v-model="form.data.contactPhone"
          :loading="$wait.is(loaders.fetchCompany)"
          :label="$t('forms.fields.phone')"
          :error-messages="errors.contactPhone"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="!isRequiredFederalState && useForeignDependencies">
      <v-col cols="12" v-if="!isRequiredFederalState">
        <h3>{{ $t('forms.fields.furtherParticipationRequirements') }}</h3>
        <p>{{ $t('forms.fields.germanRelations') }}</p>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="form.data.isForeignBranch"
          :label="$t('forms.fields.foreignBranch')"
          class="ml-2"
          dense
          :disabled="!!form.data.isHasRelations"
        />

        <v-checkbox
          v-model="form.data.isForeignRepresentation"
          :label="$t('forms.fields.foreignRepresentation')"
          class="ml-2"
          dense
          :disabled="!!form.data.isHasRelations"
        />

        <v-checkbox
          v-model="form.data.isHasRelations"
          :label="$t('forms.fields.noRelations')"
          class="ml-2"
          dense
          :disabled="
            !!form.data.isForeignBranch || !!form.data.isForeignRepresentation
          "
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-if="form.data.isForeignBranch || form.data.isForeignRepresentation"
          class="pt-0"
          v-model="form.data.relatedCompany"
          :label="$t('forms.fields.relatedCompany') | required"
          :loading="$wait.is(loaders.fetchCompany)"
        />
        <p v-if="form.data.isHasRelations">
          {{ $t('forms.fields.noRelationsMessage') }}
        </p>
      </v-col>
    </v-row>

    <slot :submit="handleSubmit" />
    <accreditation-collision-modal ref="collision"></accreditation-collision-modal>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, email, url, minLength } from 'vuelidate/lib/validators'
import { mapWaitingActions } from 'vue-wait'
import {
  CREATE_ACCREDITATION,
  FETCH_COMPANY,
  FETCH_COUNTRIES,
  FETCH_STATES,
} from '@/store/loading-types'
import validation from '@/mixins/validate'
import { addProtocolIfMissing } from '@/utilities/helpers/urls'
import {api} from "@/boot/api";
import {GENDERS} from "@/enums/genders";
import AccreditationCollisionModal from '@/components/exhibitor/Accreditation/AccreditationCollisionModal'

export default {
  name: 'CompanyForm',

  components: {
    AccreditationCollisionModal
  },

  props: {
    additionalFormData: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isOnboarding: {
      type: Boolean,
      required: false,
      default: false,
    },

    isAccreditation: {
      type: Boolean,
      required: false,
      default: false,
    },

    useForeignDependencies: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mixins: [validation],

  data() {
    return {
      form: {
        customFieldsMap: {
          country: 'countryId',
        },
        additionalParams: {
          isOnboarding: true,
        },
        data: {
          company: '',
          street: '',
          addressSupplement: '',
          postalcode: '',
          city: '',
          country: '',
          federalState: '',
          phone: '',
          email: '',
          website: '',
          contactGender: '',
          contactFirstName: '',
          contactLastName: '',
          contactEmail: '',
          contactPhone: '',
          isForeignBranch: false,
          isForeignRepresentation: false,
          isHasRelations: false,
          relatedCompany: '',
        },
        validationRules: {
          isForeignBranch: {},
          isForeignRepresentation: {},
          isHasRelations: {},
          relatedCompany: {},
          company: {
            required,
          },
          street: {
            required,
          },
          postalcode: {},
          city: {
            required,
          },
          addressSupplement: {},
          country: {
            required,
          },
          federalState: {},
          phone: {
            required,
          },
          email: {
            required,
            email,
          },
          website: {
            required,
            url,
          },
          contactGender: {},
          contactFirstName: {},
          contactLastName: {},
          contactEmail: {
            email
          },
          contactPhone: {},
        },
        custom: {
          validationSubstitutions: {
            postalcode: {minLength: {length: 4},},
          },
        },
      },
      accreditationOld: {},
      loaders: {
        fetchCompany: FETCH_COMPANY,
        fetchCountries: FETCH_COUNTRIES,
        fetchStates: FETCH_STATES,
        createAccreditation: CREATE_ACCREDITATION,
      },
    }
  },

  async created() {
    this.fetchStates()

    if (await this.fetchCompany({ ignoreApprove : true })) {
      this.setInitialFormData(this.company)
    }

    this.form.data.federalState = this.form.data.federalState?.id
    this.accreditationOld = await this.fetchAccreditationOld()

    await this.fetchCountries()
    if (!this.company.countryId) {
      this.setDefaultCountry(this.$t('common.default.country'))
    }

    await this.handleCheckCollision()
  },

  computed: {
    ...mapGetters('company', ['company', 'isCompanyFetched']),

    ...mapGetters('countries', ['countries', 'prioritizedCountries']),

    ...mapGetters('states', ['states']),

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },

    isRequiredPostalCode() {
      return (
        this.form.data.country ==
        this.countries.find((el) => el.iso31662 == 'DE')?.id
      )
    },

    isRequiredFederalState() {
      return (
        this.form.data.country ==
        this.countries.find((el) => el.iso31662 == 'DE')?.id
      )
    },

    isAvailableToSubmit() {
      if (this.form.data.isHasRelations) {
        return false
      }

      if (this.useForeignDependencies && !this.isRequiredFederalState) {
        return (
          (this.form.data.isForeignRepresentation ||
            this.form.data.isForeignBranch) &&
          this.form.data.relatedCompany
        )
      }

      return true
    },

    statesList() {
      let states = this.states

      states.push({
        id: 9999,
        name: null,
        translations: {
          en: {
            name: 'Not specified',
          },
          de: {
            name: 'Keine Angabe',
          },
        },
      })

      return states
    },
  },

  methods: {
    ...mapActions('company', ['createAccreditation', 'fetchCompany']),

    ...mapActions('countries', ['fetchCountries']),

    ...mapActions('states', ['fetchStates']),

    ...mapWaitingActions('company', {
      createAccreditation: CREATE_ACCREDITATION,
    }),

    ...mapWaitingActions('countries', {
      fetchCountries: FETCH_COUNTRIES,
    }),

    ...mapWaitingActions('states', {
      fetchStates: FETCH_STATES,
    }),

    setDefaultCountry(countryName) {
      const country = this.countries.find(
        (country) => country.country === countryName
      )
      this.form.data.country = country?.id
    },

    formatWebsite({ target: { value } }) {
      this.form.data.website = addProtocolIfMissing(value)
    },

    setValidationRules() {
      if (this.isRequiredFederalState) {
        this.form.validationRules.federalState = { required: required }
        this.form.validationRules.postalcode = { required: required, minLength: minLength(4) }
      } else {
        this.form.validationRules.federalState = {}
        this.form.validationRules.postalcode = {}
      }
    },

    async fetchAccreditationOld() {
      return await api.get(`/companies/${this.company.companyId}?ignoreApprove=0`)
    },

    async handleCheckCollision() {
      let collision = (await api.post(`/accreditations/check`, {}))

      if (collision) {
        this.$refs.collision.show()
      }
    },

    async handleSubmit() {
      this.setValidationRules()

      this.$v.$touch()
      if (this.$v.$invalid) {
        if (this.errors.federalState) {
          this.errors.federalState = this.$t(
            'forms.fields.federalStateRequired'
          )
        }

        return
      }

      if (
        await this.createAccreditation({
          ...this.getFormData(),
          isOnboarding: this.isOnboarding,
          useForeignDependencies: this.useForeignDependencies,
        })
      ) {
        await this.fetchCompany({ ignoreApprove : true })

        this.$emit('next')
      }
    },
  },

  watch: {
    company(value) {
      if (!this.isOnboarding) {
        if (Object.keys(value).length) {
          this.setInitialFormData(this.company)
        }
      }
    },

    'form.data.country'() {
      if (!this.isRequiredFederalState) {
        this.form.data.federalState = null
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
