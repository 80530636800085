<template>
  <v-dialog v-model="display" max-width="800px" persistent>
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('users.userManagement.actions.createUser') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-text-field
            v-model="form.data.email"
            :error-messages="errors.email"
            :label="$t('forms.fields.email')"
            :disabled="create"
        />
        <v-row v-if="create">
          <v-col md="2">
            <v-select
                v-model="form.data.gender"
                :items="genders"
                item-value="type"
                item-text="label"
                :label="$t('forms.fields.contactGender')"
                :error-messages="errors.gender"
                class="rounded-0"
            />
          </v-col>
          <v-col md="5">
            <v-text-field
                v-model="form.data.first_name"
                :error-messages="errors.first_name"
                :label="$t('forms.fields.firstName')"
            />
          </v-col>
          <v-col md="5">
            <v-text-field
                v-model="form.data.last_name"
                :error-messages="errors.last_name"
                :label="$t('forms.fields.lastName')"
            />
          </v-col>
        </v-row>

        <v-row v-if="create">
          <v-col md="6">
            <v-text-field
                type="password"
                v-model="form.data.password"
                :error-messages="errors.password"
                :label="$t('forms.fields.password')"
                :hint="$t('forms.hints.password')"
            >
            </v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field
                type="password"
                v-model="form.data.password_confirmation"
                :error-messages="errors.password"
                :label="$t('forms.fields.passwordConfirmation')"
                :hint="$t('forms.hints.password')"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <validation-general-error :errors="errors.general" />
      </v-card-text>
      <v-card-actions>
        <v-btn class="elevation-0" @click="cancel">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn v-if="!create" :loading="form.loading" class="elevation-0 ml-auto" color="secondary" @click="handleCheck">
          {{ $t('forms.buttons.add') }}
        </v-btn>
        <v-btn v-if="create" :loading="form.loading" class="elevation-0 ml-auto" color="secondary" @click="handleCreate">
          {{ $t('forms.fields.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation-modal ref="confirmation" @confirm="handleInvite" confirm-button-label="company.acceptInviteModalConfirmButtonLabel">
      {{ $t('company.acceptInviteModal') }}
    </confirmation-modal>
  </v-dialog>
</template>

<script>
import validation from "@/mixins/validate";
import {email, minLength, required, sameAs} from 'vuelidate/lib/validators';
import ValidationGeneralError from '@/components/base/ValidationGeneralError'
import {GENDERS} from "@/enums/genders";
import ConfirmationModal from "@/components/base/ConfirmationModal.vue";

export default {
  name: 'CompanyInviteModal',

  components: {
    ConfirmationModal,
    ValidationGeneralError
  },

  props: {
    company: {
      type: Object,
      required: true,
    }
  },

  mixins: [validation],

  data() {
    return {
      display: false,
      
      create: false,

      form: {
        loading: false,

        data: {
          email: null,
          first_name: null,
          last_name: null,
          gender: null,
          password: '',
          password_confirmation: '',
          general: ''
        },

        validationRules: {
          email: {
            required,
            email,
          },
          first_name: {},
          last_name: {},
          gender: {},
          password: {},
          password_confirmation: {},
          general: {}
        },
      },
    }
  },

  computed: {
    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`),
      }))
    },
  },

  methods: {
    show() {
      this.create = false
      this.clearFormData()
      this.resetServerErrors()

      this.display = true
    },

    hide() {
      this.display = false
    },

    cancel() {
      this.hide()
    },

    setValidationRules() {
      if (this.create) {
        this.form.validationRules.gender = {required: required}
        this.form.validationRules.first_name = {required: required}
        this.form.validationRules.last_name = {required: required}
        this.form.validationRules.password = {required: required, minLength: minLength(6)}
        this.form.validationRules.password_confirmation = {required: required, minLength: minLength(6), sameAsPassword: sameAs('password')}
      } else {
        this.form.validationRules.gender = {}
        this.form.validationRules.first_name = {}
        this.form.validationRules.last_name = {}
        this.form.validationRules.password = {}
        this.form.validationRules.password_confirmation = {}
      }
    },

    async handleCheck() {
      this.resetServerErrors()
      this.setValidationRules()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.post(`/api/companies/${this.company.companyId}/users/check`, { email: this.form.data.email, lang: this.$i18n.locale })

        this.form.loading = false

        this.$refs.confirmation.show()
      } catch (e) {
        if (e?.response?.status === 404) {
          this.create = true

          this.setValidationRules()
          this.$v.$reset()
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }
      this.form.loading = false
    },

    async handleInvite() {
      try {
        await this.$axios.post(`/api/companies/${this.company.companyId}/users/invite`, !this.create ? { email: this.form.data.email, lang: this.$i18n.locale } : this.form.data)

        this.form.loading = false

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
        this.$v.$reset()
        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }
    },

    async handleCreate() {
      this.resetServerErrors()
      this.setValidationRules()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.post(`/api/companies/${this.company.companyId}/users`, this.form.data)

        this.form.loading = false

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
        this.$v.$reset()
        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
