export default {
  validation: {
    required: '{field} is required',
    mandatory: 'This information is mandatory',
    translation: '{field} is required',
    requiredIf: '{field} is required',
    email: '{field} must be a valid e-mail',
    minLength: '{field} must be at least {length} characters',
    maxLength: '{field} must be max {length} characters',
    sameAs: '{field} must be identical to {sameAsField} field',
    sameAsPassword: '{field} must be identical to {sameAsField} field',
    checkboxRequired: '{field} must be accepted',
    url: '{field} must be a valid URL',
    minValue: '{field} must be not smaller than {limit}',
    maxValue: '{field} must be not bigger than {limit}',
    participationIsNotCreated: 'Participation is not created yet. Please, fill the exhibitors form first',
    activateCheckboxesToProceed: 'Please activate the check boxes to proceed',
    integer: '{field} must be a positive integer',
    minFileSize: 'The file must not be small than {limit}',
    maxFileSize: 'The file must not be larger than {limit}',
  },
}
