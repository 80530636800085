export default {
  settings: {
    dataAndSettings: 'Data and settings',
    manageSettings: 'Manage your settings and company data centrally here.',
    sections: {
      userCredentials: {
        title: 'Username & Password',
        subTitle: 'Here you can edit your personal access data.',
        warning: 'If you change your email address, it will need to be verified again and you will no longer be able to log in with your current email address.',
      },
      companyData: {
        title: 'Company data',
        subTitle: 'Here you can edit the general contact details.',
        warning: 'If you change your companies data, you will also have to update your accreditation!'
      },
      accreditation: {
        title: 'Accreditation',
        description: 'As an accredited company, you are authorized to book your participation in the events of the federal foreign trade fair program securely, bindingly and conveniently via this platform.',
        statuses: {
          alert: 'Before a correct price calculation can be displayed and the registration can be sent, the accreditation must be sent in and be approved by us.',
          approved: 'Your accreditation has been approved',
          carryOut: 'Your accreditation has not yet been completed',
          approvalPending: 'Your accreditation is pending approval',
          rejected: 'Your accreditation has been revoked',
          undefined: 'You haven\'t started the accreditation process yet.',
          button: {
            approved: 'Update accreditation',
            carryOut: 'Carry out accreditation',
            approvalPending: 'Update accreditation',
            rejected: 'Update accreditation',
          }
        },
      },
      notificationSettings: {
        title: 'Notification settings',
        categories: {
          title: 'Inform about the following notifications also by e-mail.',
          processesToParticipation: 'Processes to my trade fair participation',
          informationOnReservedFairs: 'Information and advice on reserved trade fairs',
          informationOnFederalFairProgram: 'Information on the federal trade fair program',
          newFeaturesAndAnnouncements: 'New features and feature announcements',
          accountAndSecurity: 'Account and security',
        },
        recipients: {
          title: 'Send notifications to these email addresses:',
          addRecipient: 'Add recipient',
        },
      },
      profileOfInterest: {
        title: 'Profile of interest',
        description: 'Which countries and which industries are interesting for your company to participate in a trade fair?',
      },
      assignedProjects: {
        title: 'Assigned projects',
        description: 'Here you will find an overview of the projects / participations assigned to you. The assignment of the projects can be edited by the following users:'
      },
    },
  },
}
