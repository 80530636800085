<template>
  <div>
    <v-container class="pa-9" fluid>
      <v-card class="elevation-1 rounded-0">
        <v-card-title>
          <h4 class="primary--text">{{ company.company }}</h4>
          <v-spacer />
          <v-chip v-if="company.accreditationApprovedAt" class="success" small>
            {{ $t('company.accredited') }}
          </v-chip>
        </v-card-title>

        <v-divider />

        <v-card-text class="text--primary">
          <v-row>
            <v-col cols="7">
              <v-row>
                <v-col cols="4">
                  <small>{{ $t('company.address') }}</small>
                  <p>
                    {{ company.company }}
                    <br />
                    {{ company.street }}
                    <br />
                    <span v-if="company.addressSupplement">
                      {{ company.addressSupplement }}
                      <br />
                    </span>
                    {{ company.postalcode }} {{ company.city }}
                    <br />
                    {{
                      company.country
                        ? company.country.translations[$i18n.locale].country
                        : ''
                    }}
                    <br />
                    {{
                      company.federalState
                        ? company.federalState.name[$i18n.locale]
                        : ''
                    }}
                  </p>
                </v-col>

                <v-col cols="4">
                  <small>{{ $t('company.contactDetails') }}</small>
                  <p>
                    <i class="fas fa-phone primary--text mr-3" />
                    {{ company.phone }}
                    <br />
                    <i class="fas fa-fax primary--text mr-3" />
                    {{ company.fax }}
                    <br />
                    <i class="fas fa-envelope primary--text mr-3" />
                    <a :href="'mailto:' + company.email">{{ company.email }}</a>
                    <br />
                    <i class="fas fa-home primary--text mr-3" />
                    <a :href="company.website" target="_blank">
                      {{ company.website }}
                    </a>
                  </p>
                  <div
                    v-if="
                      company.contactFirstName ||
                      company.contactLastName ||
                      company.contactEmail ||
                      company.contactPhone
                    "
                  >
                    <small>{{
                      $t('tables.accreditation.contactPerson')
                    }}</small>
                    <p>
                      <i class="fas fa-address-book primary--text mr-3" />
                      <span>
                        {{
                          $t(`common.salutation.${company.contactGender}`) +
                          ' ' +
                          company.contactFirstName +
                          ' ' +
                          company.contactLastName
                        }}
                      </span>
                      <br />
                      <i class="fas fa-phone primary--text mr-3" />
                      <span v-if="company.contactPhone">{{
                        company.contactPhone
                      }}</span>
                      <br />
                      <i class="fas fa-envelope primary--text mr-3" />
                      <a
                        v-if="company.contactEmail"
                        :href="'mailto:' + company.contactEmail"
                        >{{ company.contactEmail }}</a
                      >
                      <br />
                    </p>
                  </div>
                </v-col>

                <v-col cols="4" v-if="company.accreditationApprovedAt">
                  <small>{{ $t('company.registerData') }}</small>
                  <p>
                    {{ $t('forms.fields.vatIdNo') }}: {{ company.vatId }}
                    <br />
                    {{ $t('company.hrNumber') }}: {{ company.registryNumber }}
                    <br />
                    {{ $t('company.hrRegisterCourt') }}:
                    {{ company.registryCourt }}
                    <br />
                    <span :hidden="!company.hasDocument">
                      <i class="fas fa-arrow-to-bottom primary--text mr-2" />
                      <a @click="downloadDocument(company.id)">{{
                        $t('company.downloadRegisterExcerpt')
                      }}</a>
                    </span>
                  </p>
                </v-col>

                <v-col cols="12" v-if="company.accreditationApprovedAt">
                  <p>
                    <small>{{ $t('accreditation.status') }}</small>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal fa-calendar-alt fa-fw
                    </v-icon>
                    <span
                      >{{ $t('accreditation.registeredSince') }}:
                      {{ company.createdAt | dateByLocale }}
                    </span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal fa-calendar-check fa-fw
                    </v-icon>
                    <span
                      >{{ $t('accreditation.grantedOn') }}
                      {{ company.accreditationApprovedAt | dateByLocale }}</span
                    >
                  </p>

                  <p v-if="company.accreditationRejectedAt">
                    <v-icon class="error--text mb-1 mr-3" size="18">
                      fal fa-calendar-times fa-fw
                    </v-icon>
                    <span  class="error--text"
                      >{{ $t('accreditation.revokedOn') }}
                      {{ company.accreditationRejectedAt | dateByLocale }}</span
                    >
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        !company.declaredPublicSectorInvolvement
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t('accreditation.underPublicLaw.publicSectorInvolvement')
                    }}</span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        !company.declaredPublicSectorInstitution
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t('accreditation.underPublicLaw.publicSectorInstitution')
                    }}</span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        !company.declaredPublicSectorMajority
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t('accreditation.underPublicLaw.publicSectorMajority')
                    }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  company.isForeignBranch || company.isForeignRepresentation
                "
              >
                <v-col cols="12">
                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{ company.isForeignBranch ? 'fa-check' : 'fa-times' }}
                      fa-fw
                    </v-icon>
                    <span>{{ $t('tables.accreditation.foreignBranch') }}</span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        company.isForeignRepresentation
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t('tables.accreditation.foreignRepresentation')
                    }}</span>
                  </p>

                  <p>
                    <span>{{
                      $t('tables.accreditation.connectedCompany') +
                      ': ' +
                      company.relatedCompany
                    }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="company.companyId" class="mx-0">
                <v-btn class="mt-5" color="secondary" style="text-transform: none;" outlined
                       text :to="{ name: 'admin.notes', params: { id: company.companyId } }">
                  <v-icon class="mr-2" size="18">fal fa-notes</v-icon>
                  {{ $t('help.notes.company') }}
                </v-btn>
                <v-badge class="mt-5" v-if="company.notesCount" color="secondary" :content="company.notesCount">
                </v-badge>
              </v-row>
              <v-row v-if="company.accreditationApprovedAt && !company.accreditationRejectedAt" class="mx-0">
                <v-btn class="mt-5" color="secondary" style="text-transform: none;" outlined text @click="$refs.revoke.show()">
                  {{ $t('company.revokeAccreditation') }}
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="5">
              <div class="d-flex justify-space-between">
                <h2>{{ $t('users.users') }}</h2>
              </div>
              <v-alert dense outlined type="warning" v-if="!users.length">
                {{ $t('company.noUser') }}
              </v-alert>

              <ul>
                <user-company v-for="(user, i) in users" :user="user" :key="i" @submit="load"></user-company>
              </ul>

              <v-divider class="my-4" />

              <p>{{ $t('company.deleteCompany') }}</p>

              <v-btn
                :loading="$wait.is(loaders.deleteCompany)"
                :disabled="!company.canDelete"
                color="error"
                class="elevation-0"
                @click="handleDeleteCompany"
              >
                {{ $t('company.deleteCompany') }}
              </v-btn>

              <v-divider class="my-4" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="my-5" />

        <v-card-text class="text--primary py-0">
          <p>{{ $t('participation.participations') }}</p>
        </v-card-text>

        <v-data-table
          :headers="headers"
          :items="company.participations"
          item-key="id"
          :loading="$wait.is(loaders.loadCompany)"
        >
          <template v-slot:item.status="{ item }">
            <v-chip color="primary" small>
              {{ $t(`tables.participation.pageStatus.${item.status}`) }}
            </v-chip>
          </template>

          <template v-slot:item.tradeFair.nameDisplay="{ item }">
            <h3 class="primary--text">
              <strong v-if="item.tradeFair.nameAlternative" class="text-serif">
                {{ item.tradeFair.nameAlternative }}
              </strong>
              <strong v-else class="text-serif">
                {{ item.tradeFair.name }}
              </strong>
            </h3>
          </template>

          <template v-slot:item.tradeFair.city="{ item }">
            {{ item.tradeFair.city }}
          </template>

          <template v-slot:item.tradeFair.country.country="{ item }">
            {{ item.tradeFair.country.country[$i18n.locale] }}
          </template>

          <template v-slot:item.tradeFair.startDate="{ item }">
            {{ item.tradeFair.startDate | dateByLocale }}
          </template>

          <template v-slot:item.tradeFair.endDate="{ item }">
            {{ item.tradeFair.endDate | dateByLocale }}
          </template>

          <template v-slot:item.tradeFair.organizer.name="{ item }">
            {{ item.tradeFair.organizer.name }}
          </template>

          <template v-slot:item.tradeFair.contactName="{ item }">
            {{ item.tradeFair.contactName }}
            <br />
            {{ item.tradeFair.contactPhone }}
            <br />
            {{ item.tradeFair.contactEmail }}
          </template>

          <template v-slot:item.confirmation="{ item }">
            <v-btn
              v-if="item.approvedAt"
              text
              plain
              x-small
              @click="downloadRegistrationConfirmation({ participation: item })"
            >
              <v-icon class="primary--text">fal fa-arrow-down</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <confirmation-modal
        ref="revoke"
        @confirm="handleRevokeAccreditation"
    >
      {{ $t('company.revokeAccreditationModal') }}
    </confirmation-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import { FETCH_COMPANY, DELETE_COMPANY } from '@/store/loading-types'
import ConfirmationModal from '@/components/base/ConfirmationModal'
import UserCompany from '@/components/admin/Company/UserCompany'

export default {
  name: 'AdminCompany',

  components: {
    ConfirmationModal,
    UserCompany
  },

  data() {
    return {
      users: [],

      form: {
        loading: false,
      },

      loaders: {
        loadCompany: FETCH_COMPANY,
        deleteCompany: DELETE_COMPANY,
      },
    }
  },

  computed: {
    ...mapGetters('company', ['company']),

    headers() {
      return [
        {
          text: this.$t('tradeFair.tradeFair'),
          value: 'tradeFair.nameDisplay',
          sortable: true,
        },
        {
          text: this.$t('tables.tradeFair.status'),
          value: 'status',
          sortable: true,
        },
        {
          text: this.$t('company.city'),
          value: 'tradeFair.city',
          sortable: true,
        },
        {
          text: this.$t('company.country'),
          value: 'tradeFair.country.country',
          sortable: true,
        },
        {
          text: this.$t('tables.tradeFair.startAt'),
          value: 'tradeFair.startDate',
          sortable: true,
          dataType: 'Date',
        },
        {
          text: this.$t('tables.tradeFair.endAt'),
          value: 'tradeFair.endDate',
          sortable: true,
          dataType: 'Date',
        },
        {
          text: this.$t('tradeFair.DFG'),
          value: 'tradeFair.organizer.name',
          sortable: true,
        },
        {
          text: this.$t('tradeFair.projectManager'),
          value: 'tradeFair.contactName',
          sortable: true,
        },
        {
          text: this.$t('tradeFair.confirmationPDF'),
          value: 'confirmation',
          sortable: false,
          align: 'center',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ]
    },
  },

  async created() {
    if (
      await this.fetchCompany({
        id: this.$route.params.id,
        ignoreApprove: false,
      })
    ) {
      this.load()
    }
  },

  methods: {
    ...mapActions('accreditation', ['downloadDocument']),

    ...mapActions('company', ['fetchCompany', 'deleteCompany']),

    ...mapActions('participation', ['downloadRegistrationConfirmation']),

    ...mapWaitingActions('company', {
      fetchCompany: FETCH_COMPANY,
      deleteCompany: DELETE_COMPANY,
    }),

    async handleDeleteCompany() {
      await this.deleteCompany(this.company.companyId)

      this.$router.push('/admin/companies')
    },

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/companies/${this.company.companyId}/users`, {
        params: {
          'languages': '*',
          'include': [],
        },
      })).data

      this.users = response.data
      this.form.loading = false
    },

    async handleRevokeAccreditation() {
      this.form.loading = true

      await this.$axios.post(`/api/accreditations/${this.company.id}/revoke`)
      await this.fetchCompany({
        id: this.$route.params.id,
        ignoreApprove: false,
      })

      this.form.loading = false
      this.$snackbar(this.$t('common.successApiMessage'))
    },
  },
}
</script>
